// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";

function toNum(l) {
  if (l) {
    return 1;
  } else {
    return 0;
  }
}

function toUrl(l) {
  if (l) {
    return "en";
  } else {
    return "fr";
  }
}

function fromUrl(s) {
  if (s === "en") {
    return /* English */1;
  } else if (s === "fr") {
    return /* French */0;
  } else {
    return ;
  }
}

function cmp(a, b) {
  return (
          a ? 1 : 0
        ) - (
          b ? 1 : 0
        ) | 0;
}

var LangCmp = Belt_Id.MakeComparable({
      cmp: cmp
    });

function make_i18_str(french, english) {
  var m = Belt_Map.make(LangCmp);
  var m$1 = Belt_Map.set(m, /* French */0, french);
  return Belt_Map.set(m$1, /* English */1, english);
}

var langContext = React.createContext([
      /* English */1,
      (function (param) {
          
        })
    ]);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = langContext.Provider;

var Context = {
  makeProps: makeProps,
  make: make
};

function newLangFromOldLang(old_lang) {
  if (old_lang === /* English */1) {
    return /* French */0;
  } else {
    return /* English */1;
  }
}

function getCurrent(french, english) {
  var match = React.useContext(langContext);
  if (match[0]) {
    return english;
  } else {
    return french;
  }
}

var make$1 = React.memo(function (props) {
      var match = React.useContext(langContext);
      if (match[0]) {
        return props.english;
      } else {
        return props.french;
      }
    });

var $$Element = {
  make: make$1
};

var make$2 = React.memo(function (props) {
      var str = make_i18_str(props.french, props.english);
      var match = React.useContext(langContext);
      var str$1 = Belt_Map.get(str, match[0]);
      if (str$1 !== undefined) {
        return str$1;
      } else {
        return Belt_List.headExn(Belt_Map.toList(str))[1];
      }
    });

var $$String = {
  make: make$2
};

export {
  toNum ,
  toUrl ,
  fromUrl ,
  LangCmp ,
  make_i18_str ,
  langContext ,
  Context ,
  newLangFromOldLang ,
  getCurrent ,
  $$Element ,
  $$String ,
}
/* LangCmp Not a pure module */
