// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import Core from "@rjsf/core";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CatalaRuntime from "@catala-lang/rescript-catala/src/CatalaRuntime.bs.js";
import * as CatalaFrenchLaw from "@catala-lang/french-law/src/CatalaFrenchLaw.bs.js";
import * as Box$CatalaWebsite from "./Box.bs.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Lang$CatalaWebsite from "../utils/Lang.bs.js";
import * as Button$CatalaWebsite from "./Button.bs.js";

var FromJSONSchema = {};

const downloadJSONstring = (data) => {
  const blob = new Blob([data],{type:'application/json'});
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = "data.json";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
;

const readFileAsJSON = (file, callback) => {
  var reader = new FileReader();
  var contents = ""
  reader.onload = function(evt) {
    contents = evt.target.result;
    var json;
    try {
      json = JSON.parse(contents)
    } catch (error) {
      console.log(error)
      json = null;
    }
    callback(json);
  };
  reader.readAsText(file);
};
;

function Make(FormInfos) {
  var Form$Make = function (props) {
    var collapsible = props.collapsible;
    var setEventsOpt = props.setEventsOpt;
    var match = React.useState(function () {
          return FormInfos.initFormData;
        });
    var setFormData = match[1];
    var formData = match[0];
    React.useEffect((function () {
            Curry._1(setEventsOpt, (function (param) {
                    var logs;
                    try {
                      logs = CatalaRuntime.deserializedEvents(CatalaFrenchLaw.retrieveEventsSerialized(undefined));
                    }
                    catch (exn){
                      logs = [];
                    }
                    if (0 === logs.length) {
                      return ;
                    } else {
                      return logs;
                    }
                  }));
          }), [
          formData,
          setEventsOpt
        ]);
    var match$1 = React.useState(function () {
          return {};
        });
    var setUploadedFile = match$1[1];
    var uploadedFile = match$1[0];
    var fileChangeHandler = function (_event) {
      Curry._1(setUploadedFile, (_event.target.files[0]));
    };
    var retrieveFileContents = function (param) {
      if ((uploadedFile instanceof File)) {
        readFileAsJSON(uploadedFile, (function (form_data) {
                Curry._1(setFormData, (function (param) {
                        return Caml_option.some(form_data);
                      }));
              }));
        return ;
      }
      
    };
    var divider = React.createElement("div", {
          className: "mt-4 mb-4 w-full border-2 border-solid rounded-full border-tertiary"
        });
    var tmp;
    if (formData !== undefined) {
      try {
        tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "pr-2 font-semibold"
                }, FormInfos.resultLabel), React.createElement("div", {
                  className: "flex flex-row justify-center"
                }, React.createElement("div", {
                      className: "font-bold whitespace-nowrap"
                    }, Curry._1(FormInfos.computeAndPrintResult, Caml_option.valFromOption(formData)))));
      }
      catch (raw_err){
        var err = Caml_js_exceptions.internalToOCamlException(raw_err);
        console.log(err);
        tmp = React.createElement("p", {
              className: "font-bold break-all"
            }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                  french: "Erreur de calcul : ",
                  english: "Computation error: "
                }), Belt_Option.getWithDefault(Belt_Option.getWithDefault(Belt_Option.map(Caml_js_exceptions.as_js_exn(err), (function (prim) {
                            return prim.message;
                          })), ""), "unknown error, please retry the computation"));
      }
    } else {
      tmp = React.createElement("div", {
            className: "font-bold"
          }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                french: "En attente de la confirmation du formulaire...",
                english: "Waiting for the form data to be submitted..."
              }));
    }
    var form_and_display = React.createElement(React.Fragment, undefined, React.createElement("p", {
              className: "mt-4 italic"
            }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                  french: "Les données collectées par ce formulaire ne sont envoyées nulle part, et sont gérées uniquement par votre navigateur internet. \
            Les données sont traitées localement par un programme Javascript qui a été transmis avec le reste du site de Catala. \
            Ainsi, le site de Catala ne collecte aucune donnée de ses utilisateurs.",
                  english: "The data collected by this form is not sent anywhere, and is managed by your web browser. \
          The data is processed locally by a Javascript program that has been transmitted along with the rest of the Catala website. \
          Hence, the Catala website does not collect any data from its users."
                })), React.createElement(Core, {
              onSubmit: (function (t) {
                  Curry._1(setFormData, (function (param) {
                          var formData = Js_dict.get(t, "formData");
                          var match = FormInfos.formDataPostProcessing;
                          if (match !== undefined && formData !== undefined) {
                            return Caml_option.some(Curry._1(match, Caml_option.valFromOption(formData)));
                          } else {
                            return formData;
                          }
                        }));
                }),
              schema: Lang$CatalaWebsite.getCurrent(FormInfos.frenchSchema, FormInfos.englishSchema),
              uiSchema: Caml_option.some(Lang$CatalaWebsite.getCurrent(FormInfos.frenchUiSchema, FormInfos.englishUiSchema)),
              formData: Caml_option.some(Belt_Option.getWithDefault(formData, null))
            }), React.createElement("div", {
              className: "w-full inline-flex flex-col flex-wrap justify-center place-items-center my-4 border border-gray border-solid rounded p-4 shadow-sm bg-gray_light text-gray_dark shadow"
            }, tmp), divider, collapsible ? React.createElement(React.Fragment, undefined) : React.createElement("div", {
                className: "flex flex-wrap w-full justify-around gap-4 mt-4"
              }, React.createElement(Button$CatalaWebsite.Small.make, {
                    style: "py-2 px-4",
                    onClick: (function (param) {
                        var data_str = JSON.stringify(Belt_Option.getWithDefault(formData, null));
                        downloadJSONstring(data_str);
                      }),
                    children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                          french: "Exporter les données au format JSON",
                          english: "Export data to JSON format"
                        })
                  }), React.createElement(Button$CatalaWebsite.Small.make, {
                    style: "py-2 px-4",
                    onClick: (function (param) {
                        Curry._1(setFormData, (function (param) {
                                
                              }));
                      }),
                    children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                          french: "Réinitialiser le formulaire",
                          english: "Reset the form"
                        })
                  }), React.createElement(Button$CatalaWebsite.Small.make, {
                    style: "py-2 px-4",
                    onClick: retrieveFileContents,
                    children: React.createElement("div", {
                          className: "grid grid-cols-1 gap-2 justify-items-center"
                        }, React.createElement("input", {
                              name: "file",
                              type: "file",
                              onChange: fileChangeHandler
                            }), React.createElement("div", undefined, React.createElement(Lang$CatalaWebsite.$$String.make, {
                                  french: "Importer les données au format JSON",
                                  english: "Import data to JSON format"
                                })))
                  })));
    if (collapsible) {
      return React.createElement(Box$CatalaWebsite.Collapsible.make, {
                  children: form_and_display
                });
    } else {
      return form_and_display;
    }
  };
  return {
          make: Form$Make
        };
}

export {
  FromJSONSchema ,
  Make ,
}
/*  Not a pure module */
