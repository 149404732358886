// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

var make = React.memo(function (props) {
      var className = props.className;
      var className$1 = className !== undefined ? className : "";
      return React.createElement("i", {
                  className: "material-icons " + className$1
                }, props.name);
    });

export {
  make ,
}
/* make Not a pure module */
