// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Nav$CatalaWebsite from "../components/Nav.bs.js";
import * as Lang$CatalaWebsite from "../utils/Lang.bs.js";
import * as CatalaCode$CatalaWebsite from "../components/CatalaCode.bs.js";
import * as PageComponents$CatalaWebsite from "../components/PageComponents.bs.js";

var pageTitle = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Catala: tutoriel pour programmeurs anglophones",
      english: "English tutorial for Catala developers"
    });

var card_action = [
  {
    TAG: /* Internal */0,
    _0: [
      Nav$CatalaWebsite.home,
      Nav$CatalaWebsite.examples,
      Nav$CatalaWebsite.tutorialEnExample
    ]
  },
  React.createElement(Lang$CatalaWebsite.$$String.make, {
        french: "Voir le tutoriel",
        english: "See the tutorial"
      })
];

var card_content = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Ce tutoriel est le meilleur moyen de découvrir Catala de la perspective d'un développeur.\n      Le document part d'un exemple fictif d'un morceau de législation, et donne les éléments conceptuelles\n      et meilleures méthodes pour annoter la loi avec du code Catala.\n      ",
      english: "This tutorial is an opportunity to discover Catala from a developer's point of view.\n    The document goes over a fictional legislative example and give insights and tips about how annotate the\n    law with Catala code."
    });

var card = {
  title: pageTitle,
  icon: undefined,
  quote: undefined,
  action: card_action,
  content: card_content
};

function TutorialEnExample(props) {
  return React.createElement(React.Fragment, undefined, React.createElement(PageComponents$CatalaWebsite.Title.make, {
                  children: pageTitle
                }), React.createElement(CatalaCode$CatalaWebsite.DangerouslySetInnerHtml.make, {
                  html: (require("../../assets/tutorial_en.html"))
                }));
}

var make = TutorialEnExample;

export {
  pageTitle ,
  card ,
  make ,
}
/* pageTitle Not a pure module */
