// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Nav$CatalaWebsite from "../components/Nav.bs.js";
import * as Lang$CatalaWebsite from "../utils/Lang.bs.js";
import * as Link$CatalaWebsite from "../components/Link.bs.js";

var pageTitle = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Code de l'impôt sur le revenu",
      english: "French Income Tax"
    });

var card_action = [
  {
    TAG: /* Internal */0,
    _0: [
      Nav$CatalaWebsite.home,
      Nav$CatalaWebsite.ir_catala
    ]
  },
  React.createElement(Lang$CatalaWebsite.$$String.make, {
        french: "Voir le PDF",
        english: "View the PDF"
      })
];

var card_content = React.createElement(React.Fragment, undefined, React.createElement(Lang$CatalaWebsite.$$String.make, {
          french: "Ceci est une preuve de concept d'implémentation de l'impôt sur le revenu français en Catala. Le code source de l'exemple est hébergé sur ",
          english: "This is a proof of concept for the implementation of the French income tax in Catala. The source code of the example is hosted on "
        }), React.createElement(Link$CatalaWebsite.$$Text.make, {
          target: "https://gitlab.adullact.net/dgfip/ir-catala",
          children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                french: "le Gitlab public de la DGFiP",
                english: "DGFiP's public Gitlab."
              })
        }), React.createElement(Lang$CatalaWebsite.$$String.make, {
          french: ".",
          english: ". The code, like the legislative text it follows, is written in French."
        }));

var card = {
  title: pageTitle,
  icon: undefined,
  quote: undefined,
  action: card_action,
  content: card_content
};

function fetch_unzip_and_show_pdf(url, filename, id) {
  fetch(url)
  .then(response => response.arrayBuffer())
  .then(buf => {
    let buf8 = new Uint8Array(buf);
    let varlen = buf8[26] + buf8[28];
    return new Blob([buf8.slice(30 + varlen, buf.byteLength - varlen - 84)]).stream()
  })
  .then(zip => zip.pipeThrough(new DecompressionStream("deflate-raw")))
  .then(pdf => new Response(pdf).blob())
  .then(pdf =>
    document.getElementById(id)
      .setAttribute(
        "data",
        URL.createObjectURL(new File([pdf], filename, {type: "application/pdf"})))
  )
}
;

function IRcatala(props) {
  var obj = React.createElement("object", {
        id: "ir-catala-pdf",
        style: {
          height: "calc(100vh - 1.5rem)",
          left: "0",
          maxWidth: "none",
          position: "fixed",
          width: "100%"
        }
      });
  fetch_unzip_and_show_pdf("https://gitlab.adullact.net/api/v4/projects/1378/jobs/artifacts/main/download?job=pdf", "impot_revenu.pdf", "ir-catala-pdf");
  return obj;
}

var make = IRcatala;

export {
  pageTitle ,
  card ,
  make ,
}
/* pageTitle Not a pure module */
