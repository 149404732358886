// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function Flex$Row$Center(props) {
  var style = props.style;
  var style$1 = style !== undefined ? style : "";
  return React.createElement("div", {
              className: "flex flex-row justify-center items-center" + " " + style$1
            }, props.children);
}

var Center = {
  make: Flex$Row$Center
};

function Flex$Row$AlignTop(props) {
  var style = props.style;
  var style$1 = style !== undefined ? style : "";
  return React.createElement("div", {
              className: "flex flex-row justify-start items-start" + " " + style$1
            }, props.children);
}

var AlignTop = {
  make: Flex$Row$AlignTop
};

function Flex$Row$Wrap(props) {
  var style = props.style;
  var style$1 = style !== undefined ? style : "";
  return React.createElement("div", {
              className: "flex flex-row flex-wrap justify-start items-start" + " " + style$1
            }, props.children);
}

var Wrap = {
  make: Flex$Row$Wrap
};

function Flex$Row(props) {
  var style = props.style;
  var style$1 = style !== undefined ? style : "";
  return React.createElement("div", {
              className: "flex flex-row" + " " + style$1
            }, props.children);
}

var Row = {
  Center: Center,
  AlignTop: AlignTop,
  Wrap: Wrap,
  make: Flex$Row
};

function Flex$Column$Center(props) {
  var style = props.style;
  var style$1 = style !== undefined ? style : "";
  return React.createElement("div", {
              className: "flex flex-col justify-center items-center shadow" + " " + style$1
            }, props.children);
}

var Center$1 = {
  make: Flex$Column$Center
};

function Flex$Column$AlignLeft(props) {
  var style = props.style;
  var style$1 = style !== undefined ? style : "";
  return React.createElement("div", {
              className: "flex flex-col justify-start items-start" + " " + style$1
            }, props.children);
}

var AlignLeft = {
  make: Flex$Column$AlignLeft
};

var Column = {
  Center: Center$1,
  AlignLeft: AlignLeft
};

function Flex(props) {
  var style = props.style;
  var style$1 = style !== undefined ? style : "";
  return React.createElement("div", {
              className: "flex" + " " + style$1
            }, props.children);
}

var make = Flex;

export {
  Row ,
  Column ,
  make ,
}
/* react Not a pure module */
