// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Card$CatalaWebsite from "../components/Card.bs.js";
import * as Lang$CatalaWebsite from "../utils/Lang.bs.js";
import * as IRcatala$CatalaWebsite from "./IRcatala.bs.js";
import * as PageComponents$CatalaWebsite from "../components/PageComponents.bs.js";
import * as USTaxCodeExample$CatalaWebsite from "./USTaxCodeExample.bs.js";
import * as TutorialEnExample$CatalaWebsite from "./TutorialEnExample.bs.js";
import * as TutorialFrExample$CatalaWebsite from "./TutorialFrExample.bs.js";
import * as FrenchFamilyBenefitsExample$CatalaWebsite from "./FrenchFamilyBenefitsExample.bs.js";
import * as FrenchHousingBenefitsExample$CatalaWebsite from "./FrenchHousingBenefitsExample.bs.js";

function Examples(props) {
  return React.createElement(React.Fragment, undefined, React.createElement(PageComponents$CatalaWebsite.Title.make, {
                  children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                        french: "Exemples de programmes Catala",
                        english: "Catala examples"
                      })
                }), React.createElement(Card$CatalaWebsite.Presentation.FromList.make, {
                  cards: [
                    TutorialEnExample$CatalaWebsite.card,
                    TutorialFrExample$CatalaWebsite.card,
                    FrenchFamilyBenefitsExample$CatalaWebsite.card,
                    FrenchHousingBenefitsExample$CatalaWebsite.card,
                    USTaxCodeExample$CatalaWebsite.card,
                    IRcatala$CatalaWebsite.card
                  ]
                }));
}

var make = Examples;

export {
  make ,
}
/* react Not a pure module */
