// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Icon$CatalaWebsite from "./Icon.bs.js";
import * as Lang$CatalaWebsite from "../utils/Lang.bs.js";

var github_link = "https://github.com/CatalaLang/catala-website/issues";

var make = React.memo(function (props) {
      var today = String(new Date(Date.now()).getFullYear());
      return React.createElement("footer", {
                  className: "pt-2 font-sans text-center"
                }, React.createElement("div", {
                      className: "text-gray_dark pb-2 text-xs font-semibold"
                    }, "Copyright " + today + " Inria"), React.createElement("div", {
                      className: "bg-red pb-1"
                    }, React.createElement("span", undefined, React.createElement("a", {
                              className: "w-auto h-full text-background text-xs",
                              href: github_link
                            }, React.createElement(Icon$CatalaWebsite.make, {
                                  className: "float-left pl-4 pt-1",
                                  name: "warning"
                                }), React.createElement(Lang$CatalaWebsite.$$String.make, {
                                  french: "Ce site Internet est en cours de construction. Cliquez ici pour faire parvenir vos remarques et commentaires.",
                                  english: "This website is under construction, click here to give some feedback"
                                }), React.createElement(Icon$CatalaWebsite.make, {
                                  className: "float-right pr-4 pt-1",
                                  name: "warning"
                                })))));
    });

export {
  github_link ,
  make ,
}
/* make Not a pure module */
