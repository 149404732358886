// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function PageComponents$Title(props) {
  return React.createElement("h1", {
              className: "inline-flex flex-row flex-wrap justify-center items-center text-4xl uppercase pt-20 pb-8 text-center font-sans font-bold shadow-none text-background"
            }, props.children);
}

var Title = {
  make: PageComponents$Title
};

function PageComponents$Section(props) {
  return React.createElement(React.Fragment, undefined, React.createElement("h2", {
                  className: "text-3xl my-8 font-sans"
                }, React.createElement("span", {
                      className: "text-background font-sans font-semibold"
                    }, props.title)), props.children);
}

var Section = {
  make: PageComponents$Section
};

function PageComponents$SubSection(props) {
  return React.createElement(React.Fragment, undefined, React.createElement("h3", {
                  className: "text-2xl my-4 font-sans"
                }, React.createElement("span", {
                      className: "text-background font-sans font-semibold"
                    }, props.title)), props.children);
}

var SubSection = {
  make: PageComponents$SubSection
};

export {
  Title ,
  Section ,
  SubSection ,
}
/* react Not a pure module */
