// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Lang$CatalaWebsite from "../utils/Lang.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

var home_text = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Accueil",
      english: "Home"
    });

var home = {
  url: "",
  text: home_text
};

var formalization_text = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Formalisation",
      english: "Formalization"
    });

var formalization = {
  url: "formalisation",
  text: formalization_text
};

var publications_text = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Publications",
      english: "Publications"
    });

var publications = {
  url: "publications",
  text: publications_text
};

var examples_text = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Exemples",
      english: "Examples"
    });

var examples = {
  url: "examples",
  text: examples_text
};

var about_text = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "À propos",
      english: "About"
    });

var about = {
  url: "about",
  text: about_text
};

var seminar_text = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Séminaire",
      english: "Seminar"
    });

var seminar = {
  url: "seminar",
  text: seminar_text
};

var doc_text = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Documentation",
      english: "Documentation"
    });

var doc = {
  url: "doc",
  text: doc_text
};

var frenchFamilyBenefitsExample_text = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Allocations familiales",
      english: "Family benefits"
    });

var frenchFamilyBenefitsExample = {
  url: "family-benefits",
  text: frenchFamilyBenefitsExample_text
};

var frenchHousingBenefitsExample_text = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Aides au logement",
      english: "Housing benefits"
    });

var frenchHousingBenefitsExample = {
  url: "housing-benefits",
  text: frenchHousingBenefitsExample_text
};

var usTaxCode_text = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Impôts US",
      english: "US Tax"
    });

var usTaxCode = {
  url: "us-tax-code",
  text: usTaxCode_text
};

var tutorialEnExample_text = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Tutoriel en anglais",
      english: "English Tutorial"
    });

var tutorialEnExample = {
  url: "tutorial",
  text: tutorialEnExample_text
};

var tutorialFrExample_text = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Tutoriel en français",
      english: "French Tutorial"
    });

var tutorialFrExample = {
  url: "tutoriel",
  text: tutorialFrExample_text
};

var catalaManPage = {
  url: "catala",
  text: "catala"
};

var catalaLegifranceManPage = {
  url: "legifrance",
  text: "legifrance"
};

var clerkManPage = {
  url: "clerk",
  text: "clerk"
};

var ocamlDocs = {
  url: "ocaml",
  text: "ocaml"
};

var syntaxCheatSheet_text = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Aide-mémoire syntaxique",
      english: "Syntax cheat sheet"
    });

var syntaxCheatSheet = {
  url: "syntax-sheet",
  text: syntaxCheatSheet_text
};

var visualization_text = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Visualisation",
      english: "Visualization"
    });

var visualization = {
  url: "visualization",
  text: visualization_text
};

var ir_catala_text = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "IR Catala",
      english: "IR Catala"
    });

var ir_catala = {
  url: "ir-catala",
  text: ir_catala_text
};

function cmp(a, b) {
  return a.url.localeCompare(b.url) | 0;
}

function urlToNavElem(url) {
  var defaultElems = [home];
  var match = url.path;
  if (!match) {
    return [
            undefined,
            defaultElems
          ];
  }
  var rest = match.tl;
  var lang = Lang$CatalaWebsite.fromUrl(match.hd);
  var getNavElemsFrom = function (withDefaultElemsOpt, navElems, path) {
    var withDefaultElems = withDefaultElemsOpt !== undefined ? withDefaultElemsOpt : false;
    var path$1 = path.toLowerCase();
    return Belt_Option.map(Belt_Array.getBy(navElems, (function (e) {
                      return e.url === path$1;
                    })), (function (e) {
                  if (withDefaultElems) {
                    return Belt_Array.concat(defaultElems, [e]);
                  } else {
                    return [e];
                  }
                }));
  };
  var partial_arg = [
    home,
    formalization,
    publications,
    examples,
    about,
    seminar,
    doc,
    visualization,
    ir_catala
  ];
  var partial_arg$1 = true;
  var getFirstNavElems = function (param) {
    return getNavElemsFrom(partial_arg$1, partial_arg, param);
  };
  var partial_arg$2 = [
    frenchFamilyBenefitsExample,
    frenchHousingBenefitsExample,
    usTaxCode,
    tutorialEnExample,
    tutorialFrExample,
    catalaManPage,
    clerkManPage,
    catalaLegifranceManPage,
    ocamlDocs,
    syntaxCheatSheet,
    ir_catala
  ];
  var getSecondNavElems = function (param) {
    return getNavElemsFrom(undefined, partial_arg$2, param);
  };
  var partial_arg$3 = [visualization];
  var getThirdNavElems = function (param) {
    return getNavElemsFrom(undefined, partial_arg$3, param);
  };
  var elements;
  if (rest) {
    var match$1 = rest.tl;
    var onePart = rest.hd;
    if (match$1) {
      var match$2 = match$1.tl;
      var secondPart = match$1.hd;
      if (match$2) {
        if (match$2.tl) {
          elements = defaultElems;
        } else {
          var match$3 = Curry._1(getFirstNavElems, onePart);
          var match$4 = Curry._1(getSecondNavElems, secondPart);
          var match$5 = Curry._1(getThirdNavElems, match$2.hd);
          elements = match$3 !== undefined && match$4 !== undefined && match$5 !== undefined ? Belt_Array.concat(Belt_Array.concat(match$3, match$4), match$5) : defaultElems;
        }
      } else {
        var match$6 = Curry._1(getFirstNavElems, onePart);
        var match$7 = Curry._1(getSecondNavElems, secondPart);
        elements = match$6 !== undefined && match$7 !== undefined ? Belt_Array.concat(match$6, match$7) : defaultElems;
      }
    } else {
      var elts = Curry._1(getFirstNavElems, onePart);
      elements = elts !== undefined ? elts : defaultElems;
    }
  } else {
    elements = defaultElems;
  }
  return [
          lang,
          elements
        ];
}

function navElemsToUrl(lang, navs) {
  var firstPart = lang !== undefined ? Lang$CatalaWebsite.toUrl(lang) : Lang$CatalaWebsite.toUrl(/* English */1);
  var rest = Belt_Array.reduce(navs, "", (function (acc, nav) {
          return acc + ((
                    acc === "" ? "" : "/"
                  ) + nav.url);
        }));
  return "/" + (firstPart + ("/" + rest));
}

function goTo(elementPath, lang) {
  var newUrl = navElemsToUrl(lang, elementPath);
  console.log("Pushing " + newUrl);
  RescriptReactRouter.push(newUrl);
}

export {
  home ,
  formalization ,
  publications ,
  examples ,
  about ,
  seminar ,
  doc ,
  frenchFamilyBenefitsExample ,
  frenchHousingBenefitsExample ,
  usTaxCode ,
  tutorialEnExample ,
  tutorialFrExample ,
  catalaManPage ,
  catalaLegifranceManPage ,
  clerkManPage ,
  ocamlDocs ,
  syntaxCheatSheet ,
  visualization ,
  ir_catala ,
  cmp ,
  urlToNavElem ,
  navElemsToUrl ,
  goTo ,
}
/* home Not a pure module */
