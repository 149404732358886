// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Nav$CatalaWebsite from "./Nav.bs.js";
import * as Icon$CatalaWebsite from "./Icon.bs.js";
import * as Lang$CatalaWebsite from "../utils/Lang.bs.js";

function Link$Text(props) {
  var className = props.className;
  var className$1 = className !== undefined ? className : "border-solid border-green border-b hover:text-green ease-in duration-100 ";
  return React.createElement("a", {
              className: className$1,
              href: props.target,
              target: "_blank"
            }, props.children);
}

var $$Text = {
  make: Link$Text
};

function Link$Button$Small(props) {
  var className = props.className;
  var className$1 = className !== undefined ? className : "cursor-pointer bg-button_bg mt-8 mb-4 p-2 text-button_fg text-base inline-flex items-center rounded font-semibold font-sans shadow-sm hover:bg-button_bg_hover hover:text-button_fg_hover ease-in duration-100 ";
  return React.createElement(Link$Text, {
              className: className$1,
              target: props.target,
              children: props.children
            });
}

var Small = {
  make: Link$Button$Small
};

function Link$Button(props) {
  var className = props.className;
  var className$1 = className !== undefined ? className : "cursor-pointer bg-button_bg mt-4 mb-4 p-3 text-button_fg text-xl inline-flex items-center rounded font-semibold font-sans shadow-sm hover:bg-button_bg_hover hover:text-button_fg_hover ease-in duration-100 ";
  return React.createElement(Link$Text, {
              className: className$1,
              target: props.target,
              children: props.children
            });
}

var Button = {
  Small: Small,
  make: Link$Button
};

function Link$Internal$WithIcon(props) {
  var target = props.target;
  var name = props.name;
  var className = props.className;
  var className$1 = className !== undefined ? className : "cursor-pointer pt-2";
  var name$1 = name !== undefined ? name : "double_arrow";
  var match = React.useContext(Lang$CatalaWebsite.langContext);
  var lang = match[0];
  return React.createElement("a", {
              className: className$1,
              onClick: (function (param) {
                  Nav$CatalaWebsite.goTo(target, lang);
                })
            }, React.createElement("div", {
                  className: "flex flex-row flex-nowrap items-center text-green"
                }, React.createElement(Icon$CatalaWebsite.make, {
                      className: "pr-2",
                      name: name$1
                    }), React.createElement("span", {
                      className: "uppercase hover:text-secondary"
                    }, props.children)));
}

var WithIcon = {
  make: Link$Internal$WithIcon
};

function Link$Internal(props) {
  var target = props.target;
  var className = props.className;
  var className$1 = className !== undefined ? className : "cursor-pointer border-solid border-secondary border-b";
  var match = React.useContext(Lang$CatalaWebsite.langContext);
  var lang = match[0];
  return React.createElement("a", {
              className: className$1,
              onClick: (function (param) {
                  Nav$CatalaWebsite.goTo(target, lang);
                })
            }, props.children);
}

var Internal = {
  WithIcon: WithIcon,
  make: Link$Internal
};

export {
  $$Text ,
  Button ,
  Internal ,
}
/* react Not a pure module */
