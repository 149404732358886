// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Nav$CatalaWebsite from "../components/Nav.bs.js";
import * as Lang$CatalaWebsite from "../utils/Lang.bs.js";
import * as Link$CatalaWebsite from "../components/Link.bs.js";
import * as CatalaCode$CatalaWebsite from "../components/CatalaCode.bs.js";
import * as PageComponents$CatalaWebsite from "../components/PageComponents.bs.js";

var pageTitle = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Code des impôts américain",
      english: "US Tax Code"
    });

var card_action = [
  {
    TAG: /* Internal */0,
    _0: [
      Nav$CatalaWebsite.home,
      Nav$CatalaWebsite.examples,
      Nav$CatalaWebsite.usTaxCode
    ]
  },
  React.createElement(Lang$CatalaWebsite.$$String.make, {
        french: "Voir l'exemple",
        english: "See example"
      })
];

var card_content = React.createElement(React.Fragment, undefined, React.createElement(Lang$CatalaWebsite.$$String.make, {
          french: "Le contenu de cet exemple est généré par le compilateur de Catala à partir des ",
          english: "The content of this example is generated by the Catala compiler from the "
        }), React.createElement(Link$CatalaWebsite.$$Text.make, {
          target: "https://github.com/CatalaLang/catala-examples/tree/master/us_tax_code",
          children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                french: "fichiers source de l'exemple",
                english: "source code files of the example"
              })
        }), ".");

var card = {
  title: pageTitle,
  icon: undefined,
  quote: undefined,
  action: card_action,
  content: card_content
};

function USTaxCodeExample(props) {
  return React.createElement(React.Fragment, undefined, React.createElement(PageComponents$CatalaWebsite.Title.make, {
                  children: pageTitle
                }), React.createElement("p", {
                  className: "pb-16"
                }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                      french: "Le code source de cet exemple est disponible ",
                      english: "The source code for this example is available "
                    }), React.createElement(Link$CatalaWebsite.$$Text.make, {
                      target: "https://github.com/CatalaLang/catala-examples/tree/master/us_tax_code",
                      children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                            french: "ici",
                            english: "here"
                          })
                    }), React.createElement(Lang$CatalaWebsite.$$String.make, {
                      french: ". Ce que vous pouvez voir en dessous est la version \"tissée\" des fichiers sources transformés par le compilateur Catala.\n        Le tissage est un concept issu de la ",
                      english: ". What you can see here is the \"weaved\" output of the source files processed by the Catala compiler.\n        Weaving is a concept from "
                    }), React.createElement(Link$CatalaWebsite.$$Text.make, {
                      target: "https://en.wikipedia.org/wiki/Literate_programming#Workflow",
                      children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                            french: "programmation littéraire",
                            english: "literate programming"
                          })
                    }), React.createElement(Lang$CatalaWebsite.$$String.make, {
                      french: " , qui correspond à l'action d'entremêler le code et sa documentation textuelle dans un document\n         complet et lisible. Veuillez vous réferer au tutoriel pour savoir comment lire ce document.",
                      english: " corresponding to the action of interleaving together the code and its textual documentation\n         as to produce a reviewable and comprehensive document. Please refer to the tutorial for a hands-on introduction\n          on how to read this document."
                    })), React.createElement(CatalaCode$CatalaWebsite.DangerouslySetInnerHtml.make, {
                  html: (require("../../assets/us_tax_code.html"))
                }));
}

var make = USTaxCodeExample;

export {
  pageTitle ,
  card ,
  make ,
}
/* pageTitle Not a pure module */
