// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Nav$CatalaWebsite from "../components/Nav.bs.js";
import * as Card$CatalaWebsite from "../components/Card.bs.js";
import * as Lang$CatalaWebsite from "../utils/Lang.bs.js";
import * as Link$CatalaWebsite from "../components/Link.bs.js";
import * as CatalaCode$CatalaWebsite from "../components/CatalaCode.bs.js";

var githubLink = "https://github.com/CatalaLang/catala";

var githubLinkLatestRelease = "https://github.com/CatalaLang/catala/releases/latest";

var zulipLink = "https://zulip.catala-lang.org/";

var examplesCard_title = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Plus près de la source de vérité",
      english: "Closer to the source of truth"
    });

var examplesCard_icon = "check";

var examplesCard_quote = Caml_option.some(React.createElement(Lang$CatalaWebsite.$$String.make, {
          french: "Écrire le code à côté de la loi qu'il est censé suivre",
          english: "A program that should stick to the law, should reside next to the law."
        }));

var examplesCard_content = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Il est difficile de produire des implémentations correctes d'algorithmes dérivés de textes législatifs.\n    La définition d'une quantité peut être morcelée dans plusieurs sections de la source législative ou réglementaire. Catala\n    prend en compte cette situation en intercalant la source législative ou réglementaire et le code qui est censé\n    la modéliser. Pour implémenter votre programme, vous n'avez qu'à suivre la loi ligne à ligne!",
      english: "Implementations derived from legislative texts are hard to get right.\n     The specification for how one quantity is computed may be scattered across various places in your\n     legislative source. Catala addresses this problem by interleaving the legislative source with the\n     code that models it. To implement your program, just follow what the law says!"
    });

var examplesCard = {
  title: examplesCard_title,
  icon: examplesCard_icon,
  quote: examplesCard_quote,
  action: undefined,
  content: examplesCard_content
};

var docCard_title = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Un seul code, plusieurs environnements d'exécution",
      english: "One code, multiple execution targets"
    });

var docCard_icon = "device_hub";

var docCard_quote = Caml_option.some(React.createElement(Lang$CatalaWebsite.$$String.make, {
          french: "Exécuter simplement des programmes simples",
          english: "Simple code should be executed simply"
        }));

var docCard_content = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Le code dérivé d'une source législative ou réglementaire n'utilise généralement que des concepts basique de la\n programmation, qui sont présents dans tous les langages de programmation. Pourquoi y aurait-il besoin\n d'un moteur de règles où d'un environnement d'exécution complexe pour exécuter ce code ? Catala utilise\n un véritable compilateur pour vous apporter le code dont vous avez besoin dans le langage de programmation\n de votre choix. Cela inclus également des environnements d'exécution anciens utilisés dans de grosses\n organisations.",
      english: "Code derived from legislation generally uses basic programming concepts, that are present\n in every programming language. Why would you need a rules-engine or runtime environment to execute that code?\n Catala uses a fully-fledged compiler to bring you the code you need, in the programming language you need.\n That also includes legacy environments used in large organizations."
    });

var docCard = {
  title: docCard_title,
  icon: docCard_icon,
  quote: docCard_quote,
  action: undefined,
  content: docCard_content
};

var legalGuideCard_title = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Sécurité juridique",
      english: "Validation from legal"
    });

var legalGuideCard_icon = "work";

var legalGuideCard_quote = Caml_option.some(React.createElement(Lang$CatalaWebsite.$$String.make, {
          french: "Utiliser l'expertise juridique pour valider ses programmes",
          english: "Legislative code should be reviewed by legislative experts"
        }));

var legalGuideCard_content = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "La validation de programmes dérivés d'une source législative ou réglementaire repose généralement\n    sur des juristes spécialisés qui écrivent des cas de test manuellement. Or, maintenir une grande quantité\n    de cas de tests est coûteux lorsque la législation change potentiellement tous les ans. En effet, il\n    est difficile de prédire quels tests sont affectés par les changements. Catala a été  conçu en collaboration\n    avec des juristes afin de garantir la relisibilité du code par des experts métier, ce qui en augmente\n    le niveau d'assurance sans entraîner de coût supplémentaire.",
      english: "For programs derived from legislation, validation relies on lawyers who generally write\n     test cases by hand. Maintaining a large test base is costly in a context of fast-paced legislative reforms,\n     as it is difficult to predict which tests will be affected by the changes. Catala is designed with\n     law professionals to ensure that the code itself is reviewable by domain experts, increasing the\n     level of assurance for no additional cost."
    });

var legalGuideCard = {
  title: legalGuideCard_title,
  icon: legalGuideCard_icon,
  quote: legalGuideCard_quote,
  action: undefined,
  content: legalGuideCard_content
};

var foundationsCard_title = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Des fondations solides",
      english: "Solid foundations"
    });

var foundationsCard_icon = "functions";

var foundationsCard_quote = Caml_option.some(React.createElement(Lang$CatalaWebsite.$$String.make, {
          french: "Un langage de programmation conçu par des spécialistes",
          english: "A programming language made by programming language specialists"
        }));

var foundationsCard_content = React.createElement(React.Fragment, undefined, React.createElement(Lang$CatalaWebsite.$$String.make, {
          french: "Catala est développé à ",
          english: "Catala originates from "
        }), React.createElement(Link$CatalaWebsite.$$Text.make, {
          target: "https://www.inria.fr",
          children: "Inria"
        }), React.createElement(Lang$CatalaWebsite.$$String.make, {
          french: ", l'Institut National de Recherche en Informatique et en Automatique.\n   La conception du langage de programmation suit les techniques  de l'état de l'art du domaine.\n   Inspiré des langages de la famille ML, Catala possède un mécanisme inédit de logique par défaut basé sur ",
          english: ", the French National Institute for Computer Science Research.\n   The language has been designed according to state-of-the-art programming languages design principles.\n   Inspired from the ML language family, Catala features a unique default logic mechanism based on "
        }), React.createElement(Link$CatalaWebsite.$$Text.make, {
          target: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3088206",
          children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                french: "le travail de Sarah Lawsky",
                english: "the work of Sarah Lawsky"
              })
        }), ".");

var foundationsCard = {
  title: foundationsCard_title,
  icon: foundationsCard_icon,
  quote: foundationsCard_quote,
  action: undefined,
  content: foundationsCard_content
};

var frenchHomepage = (require("../../assets/french_homepage.html"));

var englishHomepage = (require("../../assets/english_homepage.html"));

function Presentation(props) {
  return React.createElement("div", {
              className: "flex flex-col gap-32"
            }, React.createElement("div", {
                  className: "flex flex-col justify-center items-center"
                }, React.createElement("div", {
                      className: "pt-48 pb-16"
                    }, React.createElement("p", {
                          className: "text-center text-2xl sm:text-4xl text-background italic font-sans font-semibold max-w-2xl sm:max-w-3xl"
                        }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                              french: "Catala est un langage dédié à l'écriture d'implémentations correctes\n             d'algorithmes dérivés de textes législatifs",
                              english: "Catala is a domain-specific programming language designed for deriving correct-by-construction\n          implementations from legislative texts."
                            }))), React.createElement("div", {
                      className: "inline-flex flex-row justify-between content-center text-xl font-semibold"
                    }, React.createElement(Link$CatalaWebsite.Internal.make, {
                          className: "cursor-pointer text-green hover:text-button_fg_hover pr-4 text-center",
                          target: [
                            Nav$CatalaWebsite.home,
                            Nav$CatalaWebsite.publications
                          ],
                          children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                                french: "Lire les publications",
                                english: "Read publications"
                              })
                        }), React.createElement(Link$CatalaWebsite.$$Text.make, {
                          className: "cursor-pointer text-green hover:text-button_fg_hover pl-4 text-center",
                          target: zulipLink,
                          children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                                french: "Rejoins-nous sur Zulip",
                                english: "Join us on Zulip"
                              })
                        })), React.createElement(Link$CatalaWebsite.Button.make, {
                      target: githubLink,
                      children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                            french: "Démarrer sur GitHub",
                            english: "Get started on GitHub"
                          })
                    }), React.createElement("div", {
                      className: "text-base font-sans"
                    }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                          french: "Apache-2.0 Licence",
                          english: "Apache-2.0 License"
                        }), React.createElement(Link$CatalaWebsite.$$Text.make, {
                          className: "cursor-pointer text-green hover:text-button_fg_hover pl-2 font-semibold",
                          target: githubLinkLatestRelease,
                          children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                                french: "GitHub v0.9.0",
                                english: "GitHub v0.9.0"
                              })
                        }))), React.createElement("div", {
                  className: "flex flex-col flex-wrap content-center justify-center items-center"
                }, React.createElement("div", {
                      className: "w-full lg:w-3/5 text-background text-center text-3xl font-bold py-8"
                    }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                          french: "Catala en action",
                          english: "Catala in action"
                        })), React.createElement("div", {
                      className: "w-full lg:w-3/5 border-solid"
                    }, React.createElement("p", {
                          className: "text-base sm:text-lg my-4 italic"
                        }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                              french: "Le principe de Catala est d'annoter directement les textes législatifs ou règlementaires\n            avec leur traduction en termes algorithmique. En voici un exemple rapide tiré d'un\n            article du code de la sécurité sociale :",
                              english: "Catala works by annotating legislative texts with their code translation.\n            Here is a quick example from the US Tax Code:"
                            })), React.createElement(Lang$CatalaWebsite.$$Element.make, {
                          french: React.createElement(CatalaCode$CatalaWebsite.DangerouslySetInnerHtml.make, {
                                html: frenchHomepage
                              }),
                          english: React.createElement(CatalaCode$CatalaWebsite.DangerouslySetInnerHtml.make, {
                                html: englishHomepage
                              })
                        }))), React.createElement("div", undefined, React.createElement("div", {
                      className: "w-full text-background text-center text-3xl font-bold py-8"
                    }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                          french: "Pourquoi utiliser Catala ?",
                          english: "Why use Catala?"
                        })), React.createElement("div", {
                      className: "text-base sm:text-lg pb-10 pt-4"
                    }, React.createElement(Card$CatalaWebsite.Presentation.FromList.make, {
                          cards: [
                            examplesCard,
                            docCard,
                            legalGuideCard,
                            foundationsCard
                          ]
                        }))));
}

var make = Presentation;

export {
  githubLink ,
  githubLinkLatestRelease ,
  zulipLink ,
  examplesCard ,
  docCard ,
  legalGuideCard ,
  foundationsCard ,
  frenchHomepage ,
  englishHomepage ,
  make ,
}
/* examplesCard Not a pure module */
