// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Nav$CatalaWebsite from "./Nav.bs.js";
import * as Flex$CatalaWebsite from "./Flex.bs.js";
import * as Icon$CatalaWebsite from "./Icon.bs.js";
import * as Lang$CatalaWebsite from "../utils/Lang.bs.js";
import * as Link$CatalaWebsite from "./Link.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

var link = "px-2 cursor-pointer text-tertiary uppercase font-semibold pr-4 hover:text-primary_light";

var SwitchLang = {
  link: link
};

var link_base = "inline-flex block px-2 font-semibold text-base cursor-pointer hover:text-primary_light ";

var link_inactive = "inline-flex block px-2 font-semibold text-base cursor-pointer hover:text-primary_light text-tertiary";

var link_active = "inline-flex block px-2 font-semibold text-base cursor-pointer hover:text-primary_light text-primary_light";

var NavElem = {
  link_base: link_base,
  link_inactive: link_inactive,
  link_active: link_active
};

var logo_hover_opacity = "opacity-75 hover:opacity-100";

var img_hover_oppacity = "opacity-75 hover:opacity-100 h-5 pr-4";

var Style = {
  SwitchLang: SwitchLang,
  NavElem: NavElem,
  logo_hover_opacity: logo_hover_opacity,
  img_hover_oppacity: img_hover_oppacity
};

function NavComponent$SwitchLang(props) {
  var match = React.useContext(Lang$CatalaWebsite.langContext);
  var setLang = match[1];
  var oldLang = match[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  return React.createElement("a", {
              className: link,
              onClick: (function (param) {
                  var match = Nav$CatalaWebsite.urlToNavElem(url);
                  Curry._1(setLang, undefined);
                  var newLang = Lang$CatalaWebsite.newLangFromOldLang(oldLang);
                  Nav$CatalaWebsite.goTo(match[1], newLang);
                })
            }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                  french: "En",
                  english: "Fr"
                }));
}

var SwitchLang$1 = {
  make: NavComponent$SwitchLang
};

function NavComponent$NavElem(props) {
  var target = props.target;
  var match = Nav$CatalaWebsite.urlToNavElem(RescriptReactRouter.useUrl(undefined, undefined));
  var currentElems = match[1];
  var currElemSize = currentElems.length;
  var targetSize = target.length;
  if (targetSize <= currElemSize) {
    currentElems.length = targetSize;
  }
  var style = 0 === Belt_Array.cmp(currentElems, target, Nav$CatalaWebsite.cmp) ? link_active : link_inactive;
  return React.createElement(React.Fragment, undefined, React.createElement(Link$CatalaWebsite.Internal.make, {
                  className: style,
                  target: target,
                  children: props.title
                }));
}

var NavElem$1 = {
  make: NavComponent$NavElem
};

var logo = (require('../../assets/logo.png'));

var github = (require('../../assets/GitHub-Mark-Light-32px.png'));

var navElems = React.createElement(React.Fragment, undefined, React.createElement(NavComponent$NavElem, {
          title: React.createElement(Lang$CatalaWebsite.$$String.make, {
                french: "À propos",
                english: "About"
              }),
          target: [
            Nav$CatalaWebsite.home,
            Nav$CatalaWebsite.about
          ]
        }), React.createElement(NavComponent$NavElem, {
          title: React.createElement(Lang$CatalaWebsite.$$String.make, {
                french: "Séminaire",
                english: "Seminar"
              }),
          target: [
            Nav$CatalaWebsite.home,
            Nav$CatalaWebsite.seminar
          ]
        }), React.createElement(NavComponent$NavElem, {
          title: React.createElement(Lang$CatalaWebsite.$$String.make, {
                french: "Exemples",
                english: "Examples"
              }),
          target: [
            Nav$CatalaWebsite.home,
            Nav$CatalaWebsite.examples
          ]
        }), React.createElement(NavComponent$NavElem, {
          title: React.createElement(Lang$CatalaWebsite.$$String.make, {
                french: "Doc",
                english: "Doc"
              }),
          target: [
            Nav$CatalaWebsite.home,
            Nav$CatalaWebsite.doc
          ]
        }), React.createElement(NavComponent$NavElem, {
          title: React.createElement(Lang$CatalaWebsite.$$String.make, {
                french: "Publications",
                english: "Publications"
              }),
          target: [
            Nav$CatalaWebsite.home,
            Nav$CatalaWebsite.publications
          ]
        }), React.createElement(NavComponent$NavElem, {
          title: React.createElement(Lang$CatalaWebsite.$$String.make, {
                french: "Formalisation",
                english: "Formalisation"
              }),
          target: [
            Nav$CatalaWebsite.home,
            Nav$CatalaWebsite.formalization
          ]
        }));

function NavComponent(props) {
  var match = React.useState(function () {
        return false;
      });
  var setIsMenuOpen = match[1];
  var isMenuOpen = match[0];
  return React.createElement(Flex$CatalaWebsite.Column.AlignLeft.make, {
              style: "w-full bg-background top-0 sticky z-10 shadow-md",
              children: null
            }, React.createElement("div", {
                  className: "w-full inline-flex flex-row justify-between sm:inline-grid sm:grid-cols-5 sm:grid-flow-row  "
                }, React.createElement("div", undefined, React.createElement(Link$CatalaWebsite.Internal.make, {
                          className: "py-2 h-full cursor-pointer inline-flex flex-row flex-nowrap items-center justify-start text-text_light pl-4 text-2xl font-sans font-bold hover:text-primary_light sm:col-span-1",
                          target: [Nav$CatalaWebsite.home],
                          children: null
                        }, React.createElement("img", {
                              className: "h-8 pr-2",
                              src: logo.default
                            }), React.createElement(Lang$CatalaWebsite.$$String.make, {
                              french: "Catala",
                              english: "Catala"
                            }))), React.createElement("div", {
                      className: "hidden sm:inline-flex sm:flex-row sm:justify-center sm:items-center sm:col-span-3"
                    }, navElems), React.createElement("div", {
                      className: "inline-flex flex-row self-center sm:col-span-1 sm:justify-end"
                    }, React.createElement(NavComponent$SwitchLang, {}), React.createElement("button", {
                          className: "inline-flex text-text_light self-center hover:text-primary_light mr-4 sm:hidden",
                          onClick: (function (param) {
                              Curry._1(setIsMenuOpen, (function (param) {
                                      return !isMenuOpen;
                                    }));
                            })
                        }, React.createElement(Icon$CatalaWebsite.make, {
                              name: "menu"
                            })))), isMenuOpen ? React.createElement("div", {
                    className: "inline-flex flex-col justify-center pl-2 pb-2 ease-in",
                    onClick: (function (param) {
                        Curry._1(setIsMenuOpen, (function (param) {
                                return !isMenuOpen;
                              }));
                      })
                  }, navElems) : React.createElement(React.Fragment, undefined));
}

var make = NavComponent;

export {
  Style ,
  SwitchLang$1 as SwitchLang,
  NavElem$1 as NavElem,
  logo ,
  github ,
  navElems ,
  make ,
}
/* logo Not a pure module */
