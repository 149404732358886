// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$CatalaWebsite from "./Icon.bs.js";
import * as Lang$CatalaWebsite from "../utils/Lang.bs.js";

function Box$Collapsible(props) {
  var labelCollapse = props.labelCollapse;
  var labelExpand = props.labelExpand;
  var labelExpand$1 = labelExpand !== undefined ? Caml_option.valFromOption(labelExpand) : React.createElement(Lang$CatalaWebsite.$$String.make, {
          french: "Ouvrir",
          english: "Open"
        });
  var labelCollapse$1 = labelCollapse !== undefined ? Caml_option.valFromOption(labelCollapse) : React.createElement(Lang$CatalaWebsite.$$String.make, {
          french: "Fermer",
          english: "Close"
        });
  var iconStyle = "float-left text-gray_dark";
  var labelStyle = "text-base font-sans font-bold text-gray_dark hover:text-green";
  var match = React.useState(function () {
        return false;
      });
  var setVisible = match[1];
  return React.createElement("div", {
              className: "text-background my-4 p-2 border bg-gray_light border-gray rounded shadow-sm overflow-y-auto max-h-128"
            }, match[0] ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: labelStyle
                      }, React.createElement("a", {
                            className: "cursor-pointer",
                            onClick: (function (param) {
                                Curry._1(setVisible, (function (param) {
                                        return false;
                                      }));
                              })
                          }, React.createElement(Icon$CatalaWebsite.make, {
                                className: iconStyle,
                                name: "expand_less"
                              }), labelCollapse$1)), React.createElement("div", {
                        className: "overflow-x-auto"
                      }, props.children)) : React.createElement("div", {
                    className: labelStyle
                  }, React.createElement("a", {
                        className: "cursor-pointer",
                        onClick: (function (param) {
                            Curry._1(setVisible, (function (param) {
                                    return true;
                                  }));
                          })
                      }, React.createElement(Icon$CatalaWebsite.make, {
                            className: iconStyle,
                            name: "expand_more"
                          }), labelExpand$1)));
}

var Collapsible = {
  make: Box$Collapsible
};

export {
  Collapsible ,
}
/* react Not a pure module */
