// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Doc$CatalaWebsite from "./pages/Doc.bs.js";
import * as Nav$CatalaWebsite from "./components/Nav.bs.js";
import * as About$CatalaWebsite from "./pages/About.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Seminar$CatalaWebsite from "./pages/Seminar.bs.js";
import * as Examples$CatalaWebsite from "./pages/Examples.bs.js";
import * as IRcatala$CatalaWebsite from "./pages/IRcatala.bs.js";
import * as Presentation$CatalaWebsite from "./pages/Presentation.bs.js";
import * as Publications$CatalaWebsite from "./pages/Publications.bs.js";
import * as Formalization$CatalaWebsite from "./pages/Formalization.bs.js";
import * as USTaxCodeExample$CatalaWebsite from "./pages/USTaxCodeExample.bs.js";
import * as TutorialEnExample$CatalaWebsite from "./pages/TutorialEnExample.bs.js";
import * as TutorialFrExample$CatalaWebsite from "./pages/TutorialFrExample.bs.js";
import * as FrenchFamilyBenefitsExample$CatalaWebsite from "./pages/FrenchFamilyBenefitsExample.bs.js";
import * as FrenchHousingBenefitsExample$CatalaWebsite from "./pages/FrenchHousingBenefitsExample.bs.js";

function toComposant(elements) {
  var match = Belt_List.fromArray(elements);
  if (!match) {
    return React.createElement(Presentation$CatalaWebsite.make, {});
  }
  var match$1 = match.tl;
  if (!match$1) {
    return React.createElement(Presentation$CatalaWebsite.make, {});
  }
  var match$2 = match$1.tl;
  var second = match$1.hd;
  var first = match.hd;
  if (!match$2) {
    if (Caml_obj.equal(first, Nav$CatalaWebsite.home) && Caml_obj.equal(second, Nav$CatalaWebsite.about)) {
      return React.createElement(About$CatalaWebsite.make, {});
    } else if (Caml_obj.equal(first, Nav$CatalaWebsite.home) && Caml_obj.equal(second, Nav$CatalaWebsite.seminar)) {
      return React.createElement(Seminar$CatalaWebsite.make, {});
    } else if (Caml_obj.equal(first, Nav$CatalaWebsite.home) && Caml_obj.equal(second, Nav$CatalaWebsite.doc)) {
      return React.createElement(Doc$CatalaWebsite.make, {});
    } else if (Caml_obj.equal(first, Nav$CatalaWebsite.home) && Caml_obj.equal(second, Nav$CatalaWebsite.formalization)) {
      return React.createElement(Formalization$CatalaWebsite.make, {});
    } else if (Caml_obj.equal(first, Nav$CatalaWebsite.home) && Caml_obj.equal(second, Nav$CatalaWebsite.publications)) {
      return React.createElement(Publications$CatalaWebsite.make, {});
    } else if (Caml_obj.equal(first, Nav$CatalaWebsite.home) && Caml_obj.equal(second, Nav$CatalaWebsite.examples)) {
      return React.createElement(Examples$CatalaWebsite.make, {});
    } else if (Caml_obj.equal(first, Nav$CatalaWebsite.home) && Caml_obj.equal(second, Nav$CatalaWebsite.ir_catala)) {
      return React.createElement(IRcatala$CatalaWebsite.make, {});
    } else {
      return React.createElement(Presentation$CatalaWebsite.make, {});
    }
  }
  var match$3 = match$2.tl;
  var third = match$2.hd;
  if (!match$3) {
    if (Caml_obj.equal(first, Nav$CatalaWebsite.home) && Caml_obj.equal(second, Nav$CatalaWebsite.doc) && Caml_obj.equal(third, Nav$CatalaWebsite.catalaManPage)) {
      return React.createElement(Doc$CatalaWebsite.CatalaManPage.make, {});
    } else if (Caml_obj.equal(first, Nav$CatalaWebsite.home) && Caml_obj.equal(second, Nav$CatalaWebsite.doc) && Caml_obj.equal(third, Nav$CatalaWebsite.clerkManPage)) {
      return React.createElement(Doc$CatalaWebsite.ClerkManPage.make, {});
    } else if (Caml_obj.equal(first, Nav$CatalaWebsite.home) && Caml_obj.equal(second, Nav$CatalaWebsite.doc) && Caml_obj.equal(third, Nav$CatalaWebsite.catalaLegifranceManPage)) {
      return React.createElement(Doc$CatalaWebsite.CatalaLegifranceManPage.make, {});
    } else if (Caml_obj.equal(first, Nav$CatalaWebsite.home) && Caml_obj.equal(second, Nav$CatalaWebsite.examples) && Caml_obj.equal(third, Nav$CatalaWebsite.frenchFamilyBenefitsExample)) {
      return React.createElement(FrenchFamilyBenefitsExample$CatalaWebsite.make, {});
    } else if (Caml_obj.equal(first, Nav$CatalaWebsite.home) && Caml_obj.equal(second, Nav$CatalaWebsite.examples) && Caml_obj.equal(third, Nav$CatalaWebsite.frenchHousingBenefitsExample)) {
      return React.createElement(FrenchHousingBenefitsExample$CatalaWebsite.make, {});
    } else if (Caml_obj.equal(first, Nav$CatalaWebsite.home) && Caml_obj.equal(second, Nav$CatalaWebsite.examples) && Caml_obj.equal(third, Nav$CatalaWebsite.tutorialEnExample)) {
      return React.createElement(TutorialEnExample$CatalaWebsite.make, {});
    } else if (Caml_obj.equal(first, Nav$CatalaWebsite.home) && Caml_obj.equal(second, Nav$CatalaWebsite.examples) && Caml_obj.equal(third, Nav$CatalaWebsite.tutorialFrExample)) {
      return React.createElement(TutorialFrExample$CatalaWebsite.make, {});
    } else if (Caml_obj.equal(first, Nav$CatalaWebsite.home) && Caml_obj.equal(second, Nav$CatalaWebsite.examples) && Caml_obj.equal(third, Nav$CatalaWebsite.usTaxCode)) {
      return React.createElement(USTaxCodeExample$CatalaWebsite.make, {});
    } else {
      return React.createElement(Presentation$CatalaWebsite.make, {});
    }
  }
  if (match$3.tl) {
    return React.createElement(Presentation$CatalaWebsite.make, {});
  }
  var fourth = match$3.hd;
  if (Caml_obj.equal(first, Nav$CatalaWebsite.home) && Caml_obj.equal(second, Nav$CatalaWebsite.examples) && Caml_obj.equal(third, Nav$CatalaWebsite.frenchFamilyBenefitsExample) && Caml_obj.equal(fourth, Nav$CatalaWebsite.visualization)) {
    return React.createElement(FrenchFamilyBenefitsExample$CatalaWebsite.Visualizer.make, {});
  } else if (Caml_obj.equal(first, Nav$CatalaWebsite.home) && Caml_obj.equal(second, Nav$CatalaWebsite.examples) && Caml_obj.equal(third, Nav$CatalaWebsite.frenchHousingBenefitsExample) && Caml_obj.equal(fourth, Nav$CatalaWebsite.visualization)) {
    return React.createElement(FrenchHousingBenefitsExample$CatalaWebsite.Visualizer.make, {});
  } else {
    return React.createElement(Presentation$CatalaWebsite.make, {});
  }
}

var manageInternalPageRedirections = (function() {
    window.scrollTo({top:0})
    if (location.hash) {
      var id = location.hash.replace('#', '')
      var elementToScroll = document.getElementById(id);
      if (elementToScroll) {
        let parent = elementToScroll.parentNode;

        // Opens the parent collapsed <details> elements.
        while (null != parent) {
          if ('DETAILS' == parent.nodeName) {
            parent.setAttribute("open", true);
            parent = null;
          }
          else {
            parent = parent.parentNode;
          }
        }
        if (elementToScroll) {
          elementToScroll.scrollIntoView(true);
        }
      }
    }
  });

function Router(props) {
  var match = Nav$CatalaWebsite.urlToNavElem(RescriptReactRouter.useUrl(undefined, undefined));
  React.useEffect(function () {
        Curry._1(manageInternalPageRedirections, undefined);
      });
  return toComposant(match[1]);
}

var make = Router;

export {
  toComposant ,
  manageInternalPageRedirections ,
  make ,
}
/* react Not a pure module */
