// Generated by ReScript, PLEASE EDIT WITH CARE


var denisMerigoux = {
  name: "Denis Merigoux",
  website: "https://merigoux.fr",
  affiliation: {
    name: "Inria - Prosecco",
    url: "https://team.inria.fr/prosecco/"
  }
};

var marieAlauzen = {
  name: "Marie Alauzen",
  website: "https://cv.archives-ouvertes.fr/marie-alauzen",
  affiliation: {
    name: "CNRS, LAMSADE",
    url: "https://www.lamsade.dauphine.fr/fr.html"
  }
};

var lilyaSlimani = {
  name: "Lilya Slimani",
  website: "https://www.linkedin.com/in/wouroud-slimani-003621183/",
  affiliation: {
    name: "EFB",
    url: "http://www.efb.fr/EC_presentation.html"
  }
};

var nicolasChataing = {
  name: "Nicolas Chataing",
  website: "https://github.com/skodt",
  affiliation: {
    name: "ENS Paris",
    url: "https://www.ens.psl.eu/departement/departement-d-informatique"
  }
};

var alainDelaet = {
  name: "Alain Delaët-Tixeuil",
  website: undefined,
  affiliation: {
    name: "Inria - Prosecco",
    url: "https://team.inria.fr/prosecco/"
  }
};

var aymericFromherz = {
  name: "Aymeric Fromherz",
  website: "https://users.ece.cmu.edu/~afromher/",
  affiliation: {
    name: "Inria - Prosecco",
    url: "https://team.inria.fr/prosecco/"
  }
};

var raphaelMonat = {
  name: "Raphaël Monat",
  website: "https://rmonat.fr/",
  affiliation: {
    name: "Inria Lille - SyCoMoRES",
    url: "https://team.inria.fr/sycomores/"
  }
};

var sarahLawsky = {
  name: "Sarah Lawsky",
  website: "http://www.law.northwestern.edu/faculty/profiles/SarahLawsky/",
  affiliation: {
    name: "Northwestern Pritzker School of Law",
    url: "http://www.law.northwestern.edu/"
  }
};

var jonathanProtzenko = {
  name: "Jonathan Protzenko",
  website: "https://jonathan.protzenko.fr",
  affiliation: {
    name: "Microsoft Research RiSE",
    url: "https://www.microsoft.com/en-us/research/group/research-software-engineering-rise/"
  }
};

var lianeHuttner = {
  name: "Liane Huttner",
  website: "https://univ-droit.fr/universitaires/48558-liane-huttner",
  affiliation: {
    name: "Université Paris-Saclay",
    url: "https://www.jm.universite-paris-saclay.fr/"
  }
};

var christopheGaie = {
  name: "Christophe Gaie",
  website: "https://gaie.webnode.page/",
  affiliation: {
    name: "DINUM",
    url: "https://www.numerique.gouv.fr/dinum/"
  }
};

var louisGesbert = {
  name: "Louis Gesbert",
  website: "https://github.com/AltGr",
  affiliation: {
    name: "Inria - Prosecco",
    url: "https://team.inria.fr/prosecco/"
  }
};

var emileRolley = {
  name: "Émile Rolley",
  website: "https://emile-rolley.com",
  affiliation: {
    name: "Malt",
    url: "https://www.malt.fr/profile/emilerolley"
  }
};

var justineBanuls = {
  name: "Justine Banuls",
  website: "https://www.linkedin.com/in/justine-banuls-getintouch/",
  affiliation: {
    name: "EFB",
    url: "http://www.efb.fr/EC_presentation.html"
  }
};

var carolineFlori = {
  name: "Caroline Flori",
  website: "https://www.linkedin.com/in/caroline-flori-2459b919b",
  affiliation: {
    name: "DGFiP",
    url: "https://www.economie.gouv.fr/dgfip"
  }
};

export {
  denisMerigoux ,
  marieAlauzen ,
  lilyaSlimani ,
  nicolasChataing ,
  alainDelaet ,
  aymericFromherz ,
  raphaelMonat ,
  sarahLawsky ,
  jonathanProtzenko ,
  lianeHuttner ,
  christopheGaie ,
  louisGesbert ,
  emileRolley ,
  justineBanuls ,
  carolineFlori ,
}
/* No side effect */
