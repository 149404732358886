// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

var typesetMathJax = (function typesetMathJax() {
    if (window.MathJax) {
      window.MathJax.typeset();
    }
  });

function CatalaCode$DangerouslySetInnerHtml(props) {
  React.useEffect(function () {
        Curry._1(typesetMathJax, undefined);
      });
  return React.createElement("div", {
              className: "border-gray rounded border" + " catala-code",
              dangerouslySetInnerHTML: {
                __html: props.html
              }
            });
}

var DangerouslySetInnerHtml = {
  make: CatalaCode$DangerouslySetInnerHtml
};

function CatalaCode$Span(props) {
  return React.createElement("span", {
              className: "text-" + props.kind
            }, props.code);
}

var Span = {
  make: CatalaCode$Span
};

function CatalaCode$Collapsible(props) {
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  var isOpen = match[0];
  var toggleButton = function (s) {
    return React.createElement("button", {
                className: "cursor-pointer rounded font-semibold hover:bg-secondary hover:text-gray_light ease-out duration-150",
                href: "#",
                onClick: (function (param) {
                    Curry._1(setIsOpen, (function (param) {
                            return !isOpen;
                          }));
                  })
              }, s);
  };
  return React.createElement(React.Fragment, undefined, props.start, isOpen ? React.createElement(React.Fragment, undefined, toggleButton(" - "), React.createElement("br", undefined), props.children) : React.createElement(React.Fragment, undefined, toggleButton("...")), props.end);
}

var Collapsible = {
  make: CatalaCode$Collapsible
};

function CatalaCode$Ids(props) {
  return Belt_Array.mapWithIndex(props.ids, (function (i, s) {
                return React.createElement("span", {
                            key: "code-id-" + String(i)
                          }, i > 0 ? React.createElement("span", {
                                  className: "op"
                                }, ".") : React.createElement(React.Fragment, undefined), s === s.toLowerCase() ? React.createElement("span", {
                                  className: "text-nv font-medium"
                                }, s) : React.createElement("span", {
                                  className: "text-nc font-bold"
                                }, s));
              }));
}

var Ids = {
  make: CatalaCode$Ids
};

function CatalaCode$Op(props) {
  return React.createElement("span", {
              className: "op"
            }, props.op);
}

var Op = {
  make: CatalaCode$Op
};

function CatalaCode(props) {
  return React.createElement("div", {
              className: "font-mono"
            }, React.createElement("pre", undefined, props.children));
}

var make = CatalaCode;

export {
  typesetMathJax ,
  DangerouslySetInnerHtml ,
  Span ,
  Collapsible ,
  Ids ,
  Op ,
  make ,
}
/* react Not a pure module */
