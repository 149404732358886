// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$CatalaWebsite from "../components/Box.bs.js";
import * as Icon$CatalaWebsite from "../components/Icon.bs.js";
import * as Lang$CatalaWebsite from "../utils/Lang.bs.js";
import * as Link$CatalaWebsite from "../components/Link.bs.js";
import * as PageComponents$CatalaWebsite from "../components/PageComponents.bs.js";

var grammar = (require("../../assets/grammar.html"));

function Formalization(props) {
  return React.createElement(React.Fragment, undefined, React.createElement(PageComponents$CatalaWebsite.Title.make, {
                  children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                        french: "Formalisation",
                        english: "Formalization"
                      })
                }), React.createElement(PageComponents$CatalaWebsite.Section.make, {
                  title: React.createElement(Lang$CatalaWebsite.$$String.make, {
                        french: "Syntaxe du langage de surface",
                        english: "Surface syntax"
                      }),
                  children: null
                }, React.createElement("p", undefined, React.createElement(Lang$CatalaWebsite.$$String.make, {
                          french: "La syntaxe du langage est générée à partir du ",
                          english: "The syntax of the language is derived from the "
                        }), React.createElement(Link$CatalaWebsite.$$Text.make, {
                          target: "https://github.com/CatalaLang/catala/blob/master/compiler/surface/parser.mly",
                          children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                                french: "fichier source du parseur",
                                english: "parser source file"
                              })
                        }), React.createElement(Lang$CatalaWebsite.$$String.make, {
                          french: " grâce à  ",
                          english: " using "
                        }), React.createElement(Link$CatalaWebsite.$$Text.make, {
                          target: "https://github.com/Lelio-Brun/Obelisk",
                          children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                                french: "Obelisk",
                                english: "Obelisk"
                              })
                        }), React.createElement(Lang$CatalaWebsite.$$String.make, {
                          french: ". Cette syntaxe est indépendante de la langue d'entrée, c'est-à-dire qu'elle est valable pour les versions\n        française et anglaise de Catala. En effet, la langue d'entrée n'affecte que la création des jetons du parseur, pas leur ordre.",
                          english: ". This syntax is language-agnostic, meaning that it is valid for both English and French versions\n        of Catala. Indeed, the language only affects the tokens, not their order."
                        })), React.createElement(Box$CatalaWebsite.Collapsible.make, {
                      children: React.createElement("div", {
                            className: "font-mono",
                            dangerouslySetInnerHTML: {
                              __html: grammar
                            }
                          })
                    }), React.createElement("p", undefined, React.createElement(Lang$CatalaWebsite.$$String.make, {
                          french: "En complément de cette description formelle de la syntaxe, un pense-bête est également\n        disponible. Celui-ci est probablement plus utile pour satisfaire votre curiosité opérationnelle\n        vis-à-vis de la syntaxe de Catala.",
                          english: "To complement this formal description of the syntax, a cheat sheet is also available\n        and is probably more practical to satisfy your hands-on syntax curiosity."
                        })), React.createElement("div", {
                      className: "flex flex-row justify-end"
                    }, React.createElement(Link$CatalaWebsite.Button.Small.make, {
                          target: "https://catalalang.github.io/catala/syntax.pdf",
                          children: null
                        }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                              french: "Accéder au pense-bête syntaxique",
                              english: "Access the syntax cheat sheet"
                            }), React.createElement(Icon$CatalaWebsite.make, {
                              className: "pl-2",
                              name: "description"
                            })))), React.createElement(PageComponents$CatalaWebsite.Section.make, {
                  title: React.createElement(Lang$CatalaWebsite.$$String.make, {
                        french: "Sémantique du cœur du langage",
                        english: "Core semantics"
                      }),
                  children: null
                }, React.createElement("p", undefined, React.createElement(Lang$CatalaWebsite.$$String.make, {
                          french: "La fonctionnalité inédite de Catala est la possiblité de donner plusieurs définitions à\n           la même variable, chaque définition étant conditionnée par une garde logique. Si la garde est vraie,\n           alors la définition est applicable. Ce comportement est adapté au style dans lequel sont écrits les\n           textes législatifs et règlementaire. Si plusieurs définitions sont applicables en même temps, alors\n           le choix s'effectue selon des règles de précédences spécifiées dans le code source.",
                          english: "Catala's unique feature is the possibility to give multiple definitions to the same variable,\n    each definition being conditioned to a logical guard. If the guard is true, then the definition\n    is applicable. This behavior is adapted to the style in which legal statutes are redacted. In the\n    case of multiple guards being true at the same time, the definition is picked according to a\n    precedence in the definitions that is specified in the source code."
                        })), React.createElement("div", {
                      className: "flex flex-row justify-end"
                    }, React.createElement(Link$CatalaWebsite.Button.Small.make, {
                          target: "https://dl.acm.org/doi/10.1145/3473582",
                          children: null
                        }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                              french: "Accéder à l'article contenant la formalisation",
                              english: "Access the complete formalization paper"
                            }), React.createElement(Icon$CatalaWebsite.make, {
                              className: "pl-2",
                              name: "description"
                            })))));
}

var make = Formalization;

export {
  grammar ,
  make ,
}
/* grammar Not a pure module */
