// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_module from "rescript/lib/es6/caml_module.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Flex$CatalaWebsite from "../components/Flex.bs.js";
import * as Icon$CatalaWebsite from "../components/Icon.bs.js";
import * as JsxPPXReactSupport from "rescript/lib/es6/jsxPPXReactSupport.js";
import * as Lang$CatalaWebsite from "../utils/Lang.bs.js";
import * as LogEvent$CatalaWebsite from "./LogEvent.bs.js";
import * as CatalaCode$CatalaWebsite from "../components/CatalaCode.bs.js";
import * as PageComponents$CatalaWebsite from "../components/PageComponents.bs.js";

var buttonStyle = "inline-flex justify-center text-gray_dark border text-base border-secondary font-semibold bg-gray shadow-sm hover:bg-gray_medium hover:shadow ease-out duration-150";

var make = React.memo(function (props) {
      var maxIndex = props.maxIndex;
      var setIndex = props.setIndex;
      var idx = props.index._0;
      return React.createElement(React.Fragment, undefined, React.createElement("div", {
                      className: "inline-flex flex-row justify-center content-center text-base font-sans"
                    }, React.createElement("button", {
                          className: "inline-flex justify-center text-gray_dark border text-base border-secondary font-semibold bg-gray shadow-sm hover:bg-gray_medium hover:shadow ease-out duration-150 rounded-l-lg pr-2",
                          onClick: (function (param) {
                              Curry._1(setIndex, (function (param) {
                                      return {
                                              TAG: /* Prev */0,
                                              _0: idx > 1 ? idx - 1 | 0 : 0
                                            };
                                    }));
                            })
                        }, React.createElement(Icon$CatalaWebsite.make, {
                              className: "h-4",
                              name: "arrow_left"
                            }), "Prev"), React.createElement("button", {
                          className: buttonStyle + " px-2",
                          onClick: (function (param) {
                              Curry._1(setIndex, (function (param) {
                                      return {
                                              TAG: /* Prev */0,
                                              _0: 0
                                            };
                                    }));
                            })
                        }, String(idx + 1 | 0) + "/" + String(maxIndex)), React.createElement("button", {
                          className: "inline-flex justify-center text-gray_dark border text-base border-secondary font-semibold bg-gray shadow-sm hover:bg-gray_medium hover:shadow ease-out duration-150 rounded-r-lg pl-2",
                          onClick: (function (param) {
                              Curry._1(setIndex, (function (param) {
                                      return {
                                              TAG: /* Next */1,
                                              _0: idx < (maxIndex - 1 | 0) ? idx + 1 | 0 : 0
                                            };
                                    }));
                            })
                        }, "Next", React.createElement(Icon$CatalaWebsite.make, {
                              name: "arrow_right"
                            }))));
    });

function Visualizer$EventNavigator(props) {
  var setVarDefs = props.setVarDefs;
  var events = props.events;
  var match = React.useState(function () {
        return {
                TAG: /* Next */1,
                _0: 0
              };
      });
  var index = match[0];
  var nbEvents = events.length;
  var idx = index._0;
  React.useEffect((function () {
          Belt_Option.forEach(setVarDefs, (function (set) {
                  Curry._1(set, (function (param) {
                          return Belt_Array.map(Belt_Array.keepWithIndex(events, (function (e, i) {
                                            switch (e.TAG | 0) {
                                              case /* VarComputation */0 :
                                                  return i < idx;
                                              case /* FunCall */1 :
                                              case /* SubScopeCall */2 :
                                                  return false;
                                              
                                            }
                                          })), (function (e) {
                                        switch (e.TAG | 0) {
                                          case /* VarComputation */0 :
                                              return e._0;
                                          case /* FunCall */1 :
                                          case /* SubScopeCall */2 :
                                              return Js_exn.raiseError("unreachable");
                                          
                                        }
                                      }));
                        }));
                }));
        }), [index]);
  var $$event = Belt_Array.get(events, idx);
  return React.createElement(React.Fragment, undefined, React.createElement(make, {
                  index: index,
                  setIndex: match[1],
                  maxIndex: nbEvents
                }), $$event !== undefined ? Curry._1(props.eventToComponent, $$event) : React.createElement(React.Fragment, undefined));
}

var scrollToAndHighlightLineNum = (function(parentElem, ids) {
    if (null != parent) {
      let id = ids[Math.floor(ids.length/2)]
      let idEscaped = id.replaceAll(/\./g, "\\\.").replaceAll(/\//g, "\\\/")

      console.log(idEscaped)
      let lineToScroll = parentElem.querySelector("#" + idEscaped)
      if (null != lineToScroll) {
        let parent = lineToScroll.parentNode;
        while (null != parent) {
          if ('DETAILS' == parent.nodeName) {
            parent.setAttribute("open", true);
            parent = null;
          }
          else {
            parent = parent.parentNode;
          }
        }
        lineToScroll.scrollIntoView({block: "center"});
        lineToScroll.className = "selected";
        var links = lineToScroll.parentNode.parentNode.parentNode.parentNode.firstChild.firstChild.firstChild.getElementsByTagName('A');
        for (var i = 0; i < links.length; i++) {
          if (ids.some(id => links[i].href.includes(id))) {
            links[i].className = "selected"
          } else {
            links[i].className = ""
          }
        }
      }
    }
  });

function Make(Form) {
  var Visualizer$Make = function (props) {
    var LogEventComponent = Caml_module.init_mod([
          "Visualizer.res",
          155,
          6
        ], {
          TAG: /* Module */0,
          _0: [
            [
              {
                TAG: /* Module */0,
                _0: [[
                    /* Function */0,
                    "make"
                  ]]
              },
              "VarComputation"
            ],
            [
              {
                TAG: /* Module */0,
                _0: [[
                    /* Function */0,
                    "make"
                  ]]
              },
              "FunCall"
            ],
            [
              /* Function */0,
              "make"
            ]
          ]
        });
    var Visualizer$MakeLogEventComponent$LogEventComponent$CollapsibleItem = function (props) {
      var kindIcon = props.kindIcon;
      var headerValueOpt = props.headerValueOpt;
      var idsOpt = props.idsOpt;
      var idsOpt$1 = idsOpt !== undefined ? Caml_option.valFromOption(idsOpt) : undefined;
      var headerValueOpt$1 = headerValueOpt !== undefined ? Caml_option.valFromOption(headerValueOpt) : undefined;
      var kindIcon$1 = kindIcon !== undefined ? Caml_option.valFromOption(kindIcon) : React.createElement(React.Fragment, undefined);
      var match = React.useState(function () {
            return false;
          });
      var setIsOpen = match[1];
      var isOpen = match[0];
      var parentDomElemRef = React.useRef(null);
      React.useEffect((function () {
              if (isOpen) {
                var match = parentDomElemRef.current;
                if (!(match == null) && idsOpt$1 !== undefined) {
                  scrollToAndHighlightLineNum(match, idsOpt$1);
                }
                
              }
              
            }), [
            isOpen,
            idsOpt$1
          ]);
      var toggleStyle = "ml-2 cursor-pointer rounded hover:bg-secondary hover:text-gray_light ease-out duration-150";
      return React.createElement("div", {
                  ref: Caml_option.some(parentDomElemRef),
                  className: "w-full"
                }, React.createElement(Flex$CatalaWebsite.Column.AlignLeft.make, {
                      style: "border-solid border-secondary border rounded mt-4 hover:border-gray_dark shadow",
                      children: null
                    }, React.createElement(Flex$CatalaWebsite.Column.AlignLeft.make, {
                          style: "w-full py-2 bg-gray_2 text-gray_dark font-semibold rounded-t border-secondary\n              " + (
                            isOpen ? " border-b" : " rounded-b"
                          ),
                          children: null
                        }, React.createElement(Flex$CatalaWebsite.Row.AlignTop.make, {
                              style: "w-full justify-between pr-2",
                              children: null
                            }, React.createElement(Flex$CatalaWebsite.Row.AlignTop.make, {
                                  style: "w-full",
                                  children: null
                                }, React.createElement("a", {
                                      className: "cursor-pointer rounded",
                                      onClick: (function (param) {
                                          Curry._1(setIsOpen, (function (param) {
                                                  return !isOpen;
                                                }));
                                        })
                                    }, isOpen ? React.createElement(Icon$CatalaWebsite.make, {
                                            className: toggleStyle,
                                            name: "arrow_drop_down"
                                          }) : React.createElement(Icon$CatalaWebsite.make, {
                                            className: toggleStyle,
                                            name: "arrow_right"
                                          })), props.headerContent), kindIcon$1), headerValueOpt$1 !== undefined ? React.createElement("div", {
                                className: "px-4"
                              }, Caml_option.valFromOption(headerValueOpt$1)) : React.createElement(React.Fragment, undefined)), isOpen ? props.children : React.createElement(React.Fragment, undefined)));
    };
    var make = React.memo(function (props) {
          var kindIcon = props.kindIcon;
          var printHeadings = props.printHeadings;
          var varDef = props.varDef;
          var printHeadings$1 = printHeadings !== undefined ? printHeadings : true;
          var pos = varDef.pos;
          var match;
          if (pos !== undefined) {
            var ids = pos.start_line !== pos.end_line ? Belt_Array.makeBy((pos.end_line - pos.start_line | 0) + 1 | 0, (function (lnum) {
                      return pos.filename + "-" + String(pos.start_line + lnum | 0);
                    })) : [pos.filename + "-" + String(pos.start_line)];
            match = [
              React.createElement(Flex$CatalaWebsite.Row.Wrap.make, {
                    children: Belt_Array.mapWithIndex(Belt_Array.reverse(pos.law_headings), (function (i, h) {
                            return JsxPPXReactSupport.createElementWithKey("law-heading-" + String(i), Flex$CatalaWebsite.Row.Center.make, {
                                        children: i < (pos.law_headings.length - 1 | 0) ? React.createElement(React.Fragment, undefined, React.createElement("p", undefined, h), React.createElement(Icon$CatalaWebsite.make, {
                                                    name: "chevron_right"
                                                  })) : React.createElement("p", {
                                                className: "font-bold"
                                              }, h)
                                      });
                          }))
                  }),
              ids
            ];
          } else {
            match = [
              React.createElement(React.Fragment, undefined),
              undefined
            ];
          }
          var headerValue = React.createElement(CatalaCode$CatalaWebsite.make, {
                children: null
              }, React.createElement(CatalaCode$CatalaWebsite.Ids.make, {
                    ids: Belt_List.toArray(varDef.name)
                  }), React.createElement(CatalaCode$CatalaWebsite.Op.make, {
                    op: " = "
                  }), React.createElement(LogEvent$CatalaWebsite.LoggedValue.make, {
                    val: varDef.value
                  }));
          var headerValueOpt = printHeadings$1 ? Caml_option.some(headerValue) : undefined;
          var headerContent = printHeadings$1 ? match[0] : headerValue;
          var kindIO;
          if (varDef.io.io_output) {
            kindIO = React.createElement(Lang$CatalaWebsite.$$String.make, {
                  french: "sortie",
                  english: "output"
                });
          } else {
            var match$1 = varDef.io.io_input;
            kindIO = match$1 !== 0 ? React.createElement(Lang$CatalaWebsite.$$String.make, {
                    french: "entrée",
                    english: "input"
                  }) : React.createElement(Lang$CatalaWebsite.$$String.make, {
                    french: "interne",
                    english: "internal"
                  });
          }
          var kindIcon$1 = kindIcon !== undefined ? Caml_option.valFromOption(kindIcon) : React.createElement("div", {
                  className: "px-2 font-semibold italic border rounded whitespace-nowrap" + (
                    Belt_Option.isSome(varDef.fun_calls) ? " text-rainforest border-rainforest bg-rainforest_50" : " text-orange border-orange bg-orange_50"
                  )
                }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                      french: "définition",
                      english: "definition"
                    }), React.createElement(Lang$CatalaWebsite.$$String.make, {
                      french: " ",
                      english: " "
                    }), kindIO);
          return React.createElement(Visualizer$MakeLogEventComponent$LogEventComponent$CollapsibleItem, {
                      idsOpt: Caml_option.some(match[1]),
                      headerContent: headerContent,
                      headerValueOpt: Caml_option.some(headerValueOpt),
                      kindIcon: Caml_option.some(kindIcon$1),
                      children: React.createElement(Flex$CatalaWebsite.Column.AlignLeft.make, {
                            children: null
                          }, React.createElement("div", {
                                className: "max-h-80 overflow-y-scroll rounded-b"
                              }, React.createElement(CatalaCode$CatalaWebsite.DangerouslySetInnerHtml.make, {
                                    html: Form.catalaCodeHTML
                                  })), Belt_Option.mapWithDefault(varDef.fun_calls, React.createElement(React.Fragment, undefined), (function (funCalls) {
                                  return React.createElement("div", {
                                              className: "w-full px-4 pb-4 border border-gray border-t"
                                            }, React.createElement(Visualizer$MakeLogEventComponent$LogEventComponent$CollapsibleItem, {
                                                  headerContent: React.createElement("p", {
                                                        className: "w-full text-gray_dark font-bold pr-4"
                                                      }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                                                            french: "Calculée à partir de ...",
                                                            english: "Computed from ..."
                                                          })),
                                                  children: React.createElement(Flex$CatalaWebsite.Column.AlignLeft.make, {
                                                        style: "w-full max-h-screen overflow-y-scroll px-4 pb-4",
                                                        children: Belt_Array.mapWithIndex(Belt_List.toArray(funCalls), (function (i, funCall) {
                                                                return JsxPPXReactSupport.createElementWithKey("fun-call-" + String(i), LogEventComponent.FunCall.make, {
                                                                            funCall: funCall
                                                                          });
                                                              }))
                                                      })
                                                }));
                                })))
                    });
        });
    var VarComputation = {
      make: make
    };
    var make$1 = React.memo(function (props) {
          var subScopeCall = props.subScopeCall;
          var match = React.useState(function () {
                return [];
              });
          var headerContent = React.createElement(CatalaCode$CatalaWebsite.make, {
                children: React.createElement(CatalaCode$CatalaWebsite.Ids.make, {
                      ids: Belt_List.toArray(subScopeCall.sname)
                    })
              });
          var kindIcon = React.createElement("div", {
                className: "px-2 font-semibold text-purple_text border border-purple_text rounded bg-purple_bg italic"
              }, "scope");
          var getHeaderContent = function (label) {
            return React.createElement(Flex$CatalaWebsite.Row.Center.make, {
                        children: null
                      }, React.createElement("p", {
                            className: "w-full text-gray_dark font-bold pr-4"
                          }, label), React.createElement("div", {
                            className: "opacity-70"
                          }, headerContent));
          };
          var inputHeaderContent = getHeaderContent(React.createElement(Lang$CatalaWebsite.$$String.make, {
                    french: "Définitions de",
                    english: "Definitions of"
                  }));
          var contentHeaderContent = getHeaderContent(React.createElement(Lang$CatalaWebsite.$$String.make, {
                    french: "Contenu de",
                    english: "Content of"
                  }));
          return React.createElement(Visualizer$MakeLogEventComponent$LogEventComponent$CollapsibleItem, {
                      headerContent: headerContent,
                      kindIcon: Caml_option.some(kindIcon),
                      children: React.createElement("div", {
                            className: "w-full px-4 pb-4"
                          }, React.createElement(Visualizer$MakeLogEventComponent$LogEventComponent$CollapsibleItem, {
                                headerContent: contentHeaderContent,
                                children: React.createElement(Flex$CatalaWebsite.Column.Center.make, {
                                      style: "max-h-screen overflow-y-scroll w-full p-4",
                                      children: React.createElement(Visualizer$EventNavigator, {
                                            events: Belt_List.toArray(subScopeCall.sbody),
                                            eventToComponent: (function ($$event) {
                                                return React.createElement(LogEventComponent.make, {
                                                            event: $$event
                                                          });
                                              }),
                                            setVarDefs: match[1]
                                          })
                                    })
                              }), React.createElement(Visualizer$MakeLogEventComponent$LogEventComponent$CollapsibleItem, {
                                headerContent: inputHeaderContent,
                                children: React.createElement(Flex$CatalaWebsite.Column.AlignLeft.make, {
                                      style: "w-full max-h-screen overflow-y-scroll px-4 pb-4 border-t border-b border-gray bg-gray_light",
                                      children: null
                                    }, Belt_Array.mapWithIndex(Belt_Array.reverse(match[0]), (function (i, varDef) {
                                            return JsxPPXReactSupport.createElementWithKey("varcomp-def-" + String(i), LogEventComponent.VarComputation.make, {
                                                        varDef: varDef,
                                                        printHeadings: false
                                                      });
                                          })), Belt_Array.mapWithIndex(Belt_List.toArray(subScopeCall.inputs), (function (i, varDef) {
                                            return JsxPPXReactSupport.createElementWithKey("varcomp-subscope-input-" + String(i), LogEventComponent.VarComputation.make, {
                                                        varDef: varDef,
                                                        kindIcon: Caml_option.some(React.createElement("div", {
                                                                  className: "px-2 font-semibold italic text-purple_text border border-purple_text rounded bg-purple_bg"
                                                                }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                                                                      french: "entrée",
                                                                      english: "input"
                                                                    })))
                                                      });
                                          })))
                              }))
                    });
        });
    var make$2 = React.memo(function (props) {
          var funCall = props.funCall;
          var kindIcon = React.createElement("div", {
                className: "px-2 font-semibold text-rainforest border border-rainforest rounded bg-rainforest_50 italic"
              }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                    french: "fonction",
                    english: "function"
                  }));
          var headerContent = React.createElement(CatalaCode$CatalaWebsite.make, {
                children: React.createElement(CatalaCode$CatalaWebsite.Ids.make, {
                      ids: Belt_List.toArray(funCall.fun_name)
                    })
              });
          var functionInput = React.createElement(Flex$CatalaWebsite.Column.AlignLeft.make, {
                style: "border-solid border-secondary border rounded mt-4 hover:border-gray_dark shadow",
                children: React.createElement(Flex$CatalaWebsite.Column.AlignLeft.make, {
                      style: "w-full bg-gray_2 text-gray_dark font-semibold py-2 rounded pr-2",
                      children: React.createElement(Flex$CatalaWebsite.Row.AlignTop.make, {
                            style: "w-full justify-between pl-2",
                            children: null
                          }, Belt_Array.map(Belt_List.toArray(funCall.fun_inputs), (function (input) {
                                  return React.createElement(CatalaCode$CatalaWebsite.make, {
                                              children: null
                                            }, React.createElement(CatalaCode$CatalaWebsite.Ids.make, {
                                                  ids: Belt_List.toArray(input.name)
                                                }), React.createElement(CatalaCode$CatalaWebsite.Op.make, {
                                                  op: " : "
                                                }), React.createElement(LogEvent$CatalaWebsite.LoggedValue.make, {
                                                  val: input.value
                                                }));
                                })), React.createElement("div", {
                                className: "px-2 font-semibold italic text-rainforest border border-rainforest rounded bg-rainforest_50"
                              }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                                    french: "entrée",
                                    english: "input"
                                  })))
                    })
              });
          var functionOutput = React.createElement(LogEventComponent.VarComputation.make, {
                varDef: funCall.output,
                kindIcon: Caml_option.some(React.createElement("div", {
                          className: "px-2 font-semibold italic text-rainforest border border-rainforest rounded bg-rainforest_50"
                        }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                              french: "sortie",
                              english: "output"
                            })))
              });
          return React.createElement(Visualizer$MakeLogEventComponent$LogEventComponent$CollapsibleItem, {
                      headerContent: headerContent,
                      kindIcon: Caml_option.some(kindIcon),
                      children: React.createElement("div", {
                            className: "w-full px-4 pb-4"
                          }, functionInput, functionOutput, 0 < Belt_List.length(funCall.body) ? React.createElement(Visualizer$MakeLogEventComponent$LogEventComponent$CollapsibleItem, {
                                  headerContent: headerContent,
                                  children: React.createElement(Flex$CatalaWebsite.Column.AlignLeft.make, {
                                        style: "w-full max-h-screen overflow-y-scroll px-4 pb-4 border-t border-b border-gray bg-gray_light",
                                        children: Belt_Array.mapWithIndex(Belt_List.toArray(funCall.body), (function (i, $$event) {
                                                return JsxPPXReactSupport.createElementWithKey("funcall-item-" + String(i), LogEventComponent.make, {
                                                            event: $$event
                                                          });
                                              }))
                                      })
                                }) : React.createElement(React.Fragment, undefined))
                    });
        });
    var FunCall = {
      make: make$2
    };
    var make$3 = React.memo(function (props) {
          var $$event = props.event;
          switch ($$event.TAG | 0) {
            case /* VarComputation */0 :
                return React.createElement(make, {
                            varDef: $$event._0
                          });
            case /* FunCall */1 :
                return React.createElement(make$2, {
                            funCall: $$event._0
                          });
            case /* SubScopeCall */2 :
                return React.createElement(make$1, {
                            subScopeCall: $$event._0
                          });
            
          }
        });
    Caml_module.update_mod({
          TAG: /* Module */0,
          _0: [
            [
              {
                TAG: /* Module */0,
                _0: [[
                    /* Function */0,
                    "make"
                  ]]
              },
              "VarComputation"
            ],
            [
              {
                TAG: /* Module */0,
                _0: [[
                    /* Function */0,
                    "make"
                  ]]
              },
              "FunCall"
            ],
            [
              /* Function */0,
              "make"
            ]
          ]
        }, LogEventComponent, {
          VarComputation: VarComputation,
          FunCall: FunCall,
          make: make$3
        });
    var Visualizer$MakeLogEventComponent = function (props) {
      return React.createElement(LogEventComponent.make, {
                  event: props.event
                });
    };
    var match = React.useState(function () {
          
        });
    var eventsOpt = match[0];
    React.useEffect((function () {
            Curry._1(Form.resetLog, undefined);
          }), []);
    return React.createElement(React.Fragment, undefined, React.createElement(PageComponents$CatalaWebsite.Title.make, {
                    children: null
                  }, Form.pageTitle, React.createElement("p", {
                        className: "text-2xl font-semibold italic font-sans rounded bg-purple_bg text-purple_text px-2 ml-2 shadow-sm "
                      }, "Viz")), React.createElement(PageComponents$CatalaWebsite.Section.make, {
                    title: React.createElement(Lang$CatalaWebsite.$$String.make, {
                          french: "Formulaire",
                          english: "Form"
                        }),
                    children: React.createElement(Form.make, {
                          setEventsOpt: match[1],
                          collapsible: false
                        })
                  }), React.createElement("div", {
                    className: "w-full h-full"
                  }, React.createElement(PageComponents$CatalaWebsite.Section.make, {
                        title: React.createElement(Lang$CatalaWebsite.$$String.make, {
                              french: "Évènements de log",
                              english: "Log events"
                            }),
                        children: React.createElement(Flex$CatalaWebsite.Column.Center.make, {
                              style: "border-solid max-h-full border border-gray rounded p-4 bg-gray_light",
                              children: eventsOpt !== undefined ? React.createElement(Visualizer$EventNavigator, {
                                      events: eventsOpt,
                                      eventToComponent: (function ($$event) {
                                          return React.createElement(Visualizer$MakeLogEventComponent, {
                                                      event: $$event
                                                    });
                                        })
                                    }) : React.createElement("p", {
                                      className: "font-bold text-gray_dark"
                                    }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                                          french: "Pas d'évènements à explorer...",
                                          english: "No events to explore..."
                                        }))
                            })
                      })));
  };
  return {
          make: Visualizer$Make
        };
}

export {
  Make ,
}
/* make Not a pure module */
