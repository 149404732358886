// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Caml_module from "rescript/lib/es6/caml_module.js";
import * as CatalaCode$CatalaWebsite from "../components/CatalaCode.bs.js";

var LoggedValue = Caml_module.init_mod([
      "LogEvent.res",
      6,
      4
    ], {
      TAG: /* Module */0,
      _0: [[
          /* Function */0,
          "make"
        ]]
    });

function LogEvent$LoggedValue(props) {
  var val = props.val;
  var depth = props.depth;
  var depth$1 = depth !== undefined ? depth : 1;
  if (typeof val === "number") {
    if (val === /* Unit */0) {
      return React.createElement(CatalaCode$CatalaWebsite.Span.make, {
                  kind: "nc",
                  code: "()"
                });
    } else {
      return React.createElement(React.Fragment, undefined, "Unembeddable");
    }
  }
  switch (val.TAG | 0) {
    case /* Bool */0 :
        return React.createElement(CatalaCode$CatalaWebsite.Span.make, {
                    kind: "mb",
                    code: Pervasives.string_of_bool(val._0)
                  });
    case /* Integer */1 :
        return React.createElement(CatalaCode$CatalaWebsite.Span.make, {
                    kind: "mi",
                    code: String(val._0)
                  });
    case /* Money */2 :
        return React.createElement(React.Fragment, undefined, React.createElement(CatalaCode$CatalaWebsite.Span.make, {
                        kind: "mf",
                        code: val._0.toString()
                      }), React.createElement(CatalaCode$CatalaWebsite.Op.make, {
                        op: " €"
                      }));
    case /* Decimal */3 :
        return React.createElement(CatalaCode$CatalaWebsite.Span.make, {
                    kind: "mf",
                    code: val._0.toString()
                  });
    case /* Date */4 :
    case /* Duration */5 :
        return React.createElement(CatalaCode$CatalaWebsite.Span.make, {
                    kind: "mi",
                    code: val._0
                  });
    case /* Enum */6 :
        var match = val._1;
        var val$1 = match[1];
        var s = match[0];
        if (val$1 === 0) {
          return React.createElement(React.Fragment, undefined, React.createElement(CatalaCode$CatalaWebsite.Ids.make, {
                          ids: [s]
                        }));
        } else {
          return React.createElement(React.Fragment, undefined, React.createElement(CatalaCode$CatalaWebsite.Ids.make, {
                          ids: [s]
                        }), React.createElement(CatalaCode$CatalaWebsite.Op.make, {
                          op: " = "
                        }), React.createElement(LoggedValue.make, {
                          depth: depth$1 + 1 | 0,
                          val: val$1
                        }));
        }
    case /* Struct */7 :
        return React.createElement(CatalaCode$CatalaWebsite.Collapsible.make, {
                    start: React.createElement(React.Fragment, undefined, React.createElement(CatalaCode$CatalaWebsite.Ids.make, {
                              ids: Belt_List.toArray(val._0)
                            }), React.createElement(CatalaCode$CatalaWebsite.Op.make, {
                              op: " = {"
                            })),
                    end: React.createElement(React.Fragment, undefined, React.createElement(CatalaCode$CatalaWebsite.Op.make, {
                              op: "}"
                            })),
                    children: null
                  }, Belt_Array.map(Belt_List.toArray(val._1), (function (attribute) {
                          var id = attribute[0];
                          return React.createElement("span", {
                                      key: id
                                    }, React.createElement(CatalaCode$CatalaWebsite.Op.make, {
                                          op: Js_string.repeat((depth$1 << 1), " ")
                                        }), React.createElement(CatalaCode$CatalaWebsite.Op.make, {
                                          op: " -- "
                                        }), React.createElement(CatalaCode$CatalaWebsite.Ids.make, {
                                          ids: [id]
                                        }), React.createElement(CatalaCode$CatalaWebsite.Op.make, {
                                          op: " = "
                                        }), React.createElement(LoggedValue.make, {
                                          depth: depth$1 + 1 | 0,
                                          val: attribute[1]
                                        }), React.createElement(CatalaCode$CatalaWebsite.Op.make, {
                                          op: ", "
                                        }), React.createElement("br", undefined));
                        })), React.createElement(CatalaCode$CatalaWebsite.Op.make, {
                        op: Js_string.repeat(((depth$1 - 1 | 0) << 1), " ")
                      }));
    case /* Array */8 :
        return React.createElement(CatalaCode$CatalaWebsite.Collapsible.make, {
                    start: React.createElement(CatalaCode$CatalaWebsite.Op.make, {
                          op: "["
                        }),
                    end: React.createElement(CatalaCode$CatalaWebsite.Op.make, {
                          op: "]"
                        }),
                    children: null
                  }, Belt_Array.mapWithIndex(val._0, (function (i, val) {
                          return React.createElement("span", {
                                      key: String(i)
                                    }, React.createElement(CatalaCode$CatalaWebsite.Op.make, {
                                          op: Js_string.repeat((depth$1 << 1), " ")
                                        }), React.createElement(LoggedValue.make, {
                                          depth: depth$1 + 1 | 0,
                                          val: val
                                        }), React.createElement(CatalaCode$CatalaWebsite.Op.make, {
                                          op: ","
                                        }), React.createElement("br", undefined));
                        })), React.createElement(CatalaCode$CatalaWebsite.Op.make, {
                        op: Js_string.repeat(((depth$1 - 1 | 0) << 1), " ")
                      }));
    
  }
}

Caml_module.update_mod({
      TAG: /* Module */0,
      _0: [[
          /* Function */0,
          "make"
        ]]
    }, LoggedValue, {
      make: LogEvent$LoggedValue
    });

export {
  LoggedValue ,
}
/* LoggedValue Not a pure module */
