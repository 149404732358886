// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CatalaFrenchLaw from "@catala-lang/french-law/src/CatalaFrenchLaw.bs.js";
import * as Nav$CatalaWebsite from "../components/Nav.bs.js";
import * as Form$CatalaWebsite from "../components/Form.bs.js";
import * as Icon$CatalaWebsite from "../components/Icon.bs.js";
import * as Lang$CatalaWebsite from "../utils/Lang.bs.js";
import * as Link$CatalaWebsite from "../components/Link.bs.js";
import * as Button$CatalaWebsite from "../components/Button.bs.js";
import * as CatalaCode$CatalaWebsite from "../components/CatalaCode.bs.js";
import * as Visualizer$CatalaWebsite from "../viz/Visualizer.bs.js";
import * as PageComponents$CatalaWebsite from "../components/PageComponents.bs.js";

var pageTitle = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Calcul des aides au logement",
      english: "French housing benefits computation"
    });

var catalaCodeHTML = (require("../../assets/aides_logement.html"));

var frenchUiSchema = (require("../../assets/aides_logement_ui_fr.schema.jsx"));

var englishUiSchema = (require("../../assets/aides_logement_ui_en.schema.jsx"));

var englishSchema = (require("../../assets/aides_logement_schema_en.json"));

var frenchSchema = (require("../../assets/aides_logement_schema_fr.json"));

var frenchUiSchema$1 = frenchUiSchema["uiSchema"];

var englishUiSchema$1 = englishUiSchema["uiSchema"];

var resultLabel = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Montant mensuel brut des aides au logement :",
      english: "Housing benefits gross monthly amount:"
    });

var initFormData = Caml_option.some((require("../../assets/aides_logement_init.json")));

var formDataPostProcessing = (function (data) {
  var i = 0;
  for (var pac of data.menageIn.personnesACharge) {
    if (pac.kind == "EnfantACharge") {
      pac.payload.identifiant = i;
      i++;
    }
  }
  return data;
});

function computeAndPrintResult(input) {
  return React.createElement(React.Fragment, undefined, React.createElement("span", {
                  className: "text-mb font-mono"
                }, CatalaFrenchLaw.computeAidesAuLogement(input)), " €");
}

var FormInfos = {
  englishSchema: englishSchema,
  frenchSchema: frenchSchema,
  frenchUiSchema: frenchUiSchema$1,
  englishUiSchema: englishUiSchema$1,
  resultLabel: resultLabel,
  initFormData: initFormData,
  formDataPostProcessing: formDataPostProcessing,
  computeAndPrintResult: computeAndPrintResult
};

var Form = Form$CatalaWebsite.Make({
      englishSchema: englishSchema,
      englishUiSchema: englishUiSchema$1,
      frenchSchema: frenchSchema,
      frenchUiSchema: frenchUiSchema$1,
      resultLabel: resultLabel,
      initFormData: initFormData,
      formDataPostProcessing: formDataPostProcessing,
      computeAndPrintResult: computeAndPrintResult
    });

var Visualizer = Visualizer$CatalaWebsite.Make({
      pageTitle: pageTitle,
      catalaCodeHTML: catalaCodeHTML,
      resetLog: CatalaFrenchLaw.resetLog,
      make: Form.make
    });

var card_title = React.createElement(Lang$CatalaWebsite.$$String.make, {
      french: "Aides au logement",
      english: "French housing benefits"
    });

var card_action = [
  {
    TAG: /* Internal */0,
    _0: [
      Nav$CatalaWebsite.home,
      Nav$CatalaWebsite.examples,
      Nav$CatalaWebsite.frenchHousingBenefitsExample
    ]
  },
  React.createElement(Lang$CatalaWebsite.$$String.make, {
        french: "Voir l'exemple",
        english: "See example"
      })
];

var card_content = React.createElement(React.Fragment, undefined, React.createElement(Lang$CatalaWebsite.$$String.make, {
          french: "Le contenu de cet exemple est généré par le compilateur Catala à partir des ",
          english: "The content of the example is generated by the Catala compiler from the "
        }), React.createElement(Link$CatalaWebsite.$$Text.make, {
          target: "https://github.com/CatalaLang/catala-examples/tree/master/aides_logement",
          children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                french: "sources de l'exemple",
                english: "source code files of the example"
              })
        }), React.createElement(Lang$CatalaWebsite.$$String.make, {
          french: ".",
          english: ". The code, like the legislative text it follows, is written in French."
        }));

var card = {
  title: card_title,
  icon: undefined,
  quote: undefined,
  action: card_action,
  content: card_content
};

function FrenchHousingBenefitsExample(props) {
  return React.createElement(React.Fragment, undefined, React.createElement(PageComponents$CatalaWebsite.Title.make, {
                  children: pageTitle
                }), React.createElement("p", undefined, React.createElement(Lang$CatalaWebsite.$$String.make, {
                      french: "Le code source de cet exemple est disponible ",
                      english: "The source code for this example is available "
                    }), React.createElement(Link$CatalaWebsite.$$Text.make, {
                      target: "https://github.com/CatalaLang/catala-examples/tree/master/aides_logement",
                      children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                            french: "ici",
                            english: "here"
                          })
                    }), React.createElement(Lang$CatalaWebsite.$$String.make, {
                      french: ". Ce que vous pouvez voir en dessous est la version \"tissée\" des fichiers sources transformés par le compilateur Catala.\n        Le tissage est un concept issu de la ",
                      english: ". What you can see here is the \"weaved\" output of the source files processed by the Catala compiler.\n        Weaving is a concept from "
                    }), React.createElement(Link$CatalaWebsite.$$Text.make, {
                      target: "https://en.wikipedia.org/wiki/Literate_programming#Workflow",
                      children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                            french: "programmation littéraire",
                            english: "literate programming"
                          })
                    }), React.createElement(Lang$CatalaWebsite.$$String.make, {
                      french: " , qui correspond à l'action d'entremêler le code et sa documentation textuelle dans un document\n         complet et lisible. Veuillez vous réferer au tutoriel pour savoir comment lire ce document.",
                      english: " corresponding to the action of interleaving together the code and its textual documentation\n         as to produce a reviewable and comprehensive document. Please refer to the tutorial for a hands-on introduction\n          on how to read this document."
                    })), React.createElement(PageComponents$CatalaWebsite.Section.make, {
                  title: React.createElement(Lang$CatalaWebsite.$$String.make, {
                        french: "Calculateur en ligne",
                        english: "Online computation"
                      }),
                  children: null
                }, React.createElement("div", {
                      className: "inline-flex justify-end"
                    }, React.createElement(Button$CatalaWebsite.Internal.make, {
                          target: [
                            Nav$CatalaWebsite.home,
                            Nav$CatalaWebsite.examples,
                            Nav$CatalaWebsite.frenchHousingBenefitsExample,
                            Nav$CatalaWebsite.visualization
                          ],
                          children: null
                        }, React.createElement(Icon$CatalaWebsite.make, {
                              className: "pr-2",
                              name: "explore"
                            }), React.createElement(Lang$CatalaWebsite.$$String.make, {
                              french: "Basculer vers le mode explications détaillées",
                              english: "Switch to detailed explanation mode"
                            }))), React.createElement(Form.make, {
                      setEventsOpt: (function (param) {
                          
                        }),
                      collapsible: true
                    })), React.createElement(PageComponents$CatalaWebsite.Section.make, {
                  title: React.createElement(Lang$CatalaWebsite.$$String.make, {
                        french: "Code source",
                        english: "Source code"
                      }),
                  children: React.createElement(CatalaCode$CatalaWebsite.DangerouslySetInnerHtml.make, {
                        html: catalaCodeHTML
                      })
                }));
}

var make = FrenchHousingBenefitsExample;

export {
  pageTitle ,
  catalaCodeHTML ,
  frenchUiSchema ,
  englishUiSchema ,
  FormInfos ,
  Form ,
  Visualizer ,
  card ,
  make ,
}
/* pageTitle Not a pure module */
