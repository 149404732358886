// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Lang$CatalaWebsite from "../utils/Lang.bs.js";
import * as Link$CatalaWebsite from "../components/Link.bs.js";
import * as People$CatalaWebsite from "../data/People.bs.js";
import * as PageComponents$CatalaWebsite from "../components/PageComponents.bs.js";

function About$Person(props) {
  var person = props.person;
  var website = person.website;
  return React.createElement("li", {
              className: "pl-6 pb-4"
            }, website !== undefined ? React.createElement(Link$CatalaWebsite.$$Text.make, {
                    target: website,
                    children: person.name
                  }) : person.name, React.createElement("span", {
                  className: "pl-2"
                }, "(", React.createElement(Link$CatalaWebsite.$$Text.make, {
                      target: person.affiliation.url,
                      children: person.affiliation.name
                    }), ")"));
}

var Person = {
  make: About$Person
};

function About(props) {
  return React.createElement(React.Fragment, undefined, React.createElement(PageComponents$CatalaWebsite.Title.make, {
                  children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                        french: "À propos",
                        english: "About"
                      })
                }), React.createElement("p", undefined, React.createElement(Lang$CatalaWebsite.$$String.make, {
                      french: "En 2019, une initiative a été lancée au\n        sein de l'Institut National de Recherche en Informatique et en Automatique afin de créer un nouveau\n        langage de programmation pour transformer la loi en code : Catala. Ce langage est issu du domaine\n        des méthodes formelles, utilisées pour s'assurer que le logiciel se comporte comme prévu\n        dans de nombreux secteurs où la sécurité est cruciale, comme l'aviation ou le nucléaire. Le projet\n        est mené par Denis Merigoux de ",
                      english: "In 2019, the French National Research Institute for Computer Science (Inria) has initiated an\n      initiative focused on developing a new coding language for rules as code: Catala. The language is based on\n      the field of formal methods, which are used in safety-critical domains like avionics or nuclear power\n      plants to ensure that software behaves as expected, given a precise and unambiguous description of\n      the expected behavior. Led by Denis Merigoux from the "
                    }), React.createElement(Link$CatalaWebsite.$$Text.make, {
                      target: "https://prosecco.gforge.inria.fr/",
                      children: React.createElement(Lang$CatalaWebsite.$$String.make, {
                            french: "l'équipe Prosecco d'Inria",
                            english: "Inria Prosecco group"
                          })
                    }), React.createElement(Lang$CatalaWebsite.$$String.make, {
                      french: ", en collaboration avec des universitaires de Paris Panthéon-Sorbonne, de la\n           Northwestern Pritzker School of Law de Chicago et de Microsoft Research. Le but du langage est d'atteindre une équivalence\n           sémantique entre le code et la loi qui est sa source de vérité.",
                      english: " , in collaboration with academics from the Paris Panthéon-Sorbonne\n       University, the Northwestern Pritzker School of Law and Microsoft Research, Catala is designed to achieve semantic\n       equivalence with the law itself (its fundamental source of truth)."
                    })), React.createElement("p", undefined, React.createElement(Lang$CatalaWebsite.$$String.make, {
                      french: "Catala est unique dans son domaine car il utilise une technique appelée programmation\n            littéraire, où chaque ligne de texte législatif ou réglementaire est annoté par un petit morceau\n            de code. Cela permet à des experts non-techniques comme des législateurs ou des juristes de\n            comprendre localement la relation entre code et loi. De cette façon, les programmes Catala peuvent\n            facilement être vérifiés et validés. De plus, un outil indispensable, le compilateur,\n            traduit le code Catala vers divers langages de programmations plus traditionnels, rendant le\n            système complètement interopérable. Par exemple, le compilateur peut générer du Javascript pour\n            les applications web, du SAS pour les économistes et du COBOL pour les anciens ordinateurs centraux.\n            Crucialement, le programme traduit par le compilateur est assuré de se comporter de la même manière\n            que le programme Catala. En utilisant la compilation, le code peut être écrit une seule fois et\n            déployé partout. Cela évite d'écrire manuellement plusieurs versions du même code et d'augmenter\n            la probabilité de boggue. Catala n'en est cependant qu'à ses débuts ; les prochaines étapes\n            pour le projet sont la finalisation du compilateur, ainsi que la transformation d'un gros morceau\n            de législation en code afin de prouver l'utilié du langage.",
                      english: "Catala is unique because of its use of a style called literate programming, which sees each line of\n        a legislative style text annotated with a snippet of code. This is of obvious benefit because it allows\n        non-technical experts, such as policy makers and lawyers, to understand the representation of the code\n        in relation to the legislation or rules. This allows Catala programmes to be easily verified and\n        validated. Catala also comprises a compiler, which is a mechanism that allows for code to be\n        translated into a range of programming languages, which improves interoperability. For example,\n        the compiler can generate Javascript for web applications, SAS for economic models and COBOL for\n        legacy environments. Crucially, the translated output will be guaranteed to behave in the same\n        way as the original Catala programme. By using compilation, the code can be written once and be\n        deployed everywhere; this avoids the need to manually write multiple versions of the code, which\n        increases the chances of bugs. Catala remains an early stage project. In the future, the team is\n        working on finalising the development of a compiler (e.g. for multiple languages including\n        Javascript, Python, etc.) and implementing a large-size body of legislation to demonstrate the\n        tool's utility."
                    })), React.createElement("p", {
                  className: "float-right text-green pt-4 italic"
                }, React.createElement(Lang$CatalaWebsite.$$String.make, {
                      french: "Source : l'équipe de Catala ainsi que James Mohun, de l'observatoire de l'innovation dans le secteur public de l'OCDE (2020)",
                      english: "Text credits: the Catala team and James Mohun from the OECD Public Sector Innovation Observatory (2020)"
                    })), React.createElement(PageComponents$CatalaWebsite.Section.make, {
                  title: React.createElement(Lang$CatalaWebsite.$$String.make, {
                        french: "Nommage",
                        english: "Naming"
                      }),
                  children: React.createElement("p", undefined, React.createElement(Lang$CatalaWebsite.$$String.make, {
                            french: "Ce langage de programmation est nommé d'après Pierre Catala, qui est avec Lucien Mehl un des pionners de l'informatique juridique française.\n        Attention, le nom Catala est typographiquement proche du nom de la langue catalane écrite en catalan : Català. Cependant, le champ\n        d'application très restreint de notre langage de programmation ne devrait pas cause de confusion étant donné le rayonnement et l'influence\n        conséquents de la langue et culture catalane.",
                            english: "This programming language is named after Pierre Catala who is, together with\n        Lucien Mehl, a pioneer of French legal informatics. Beware, the name Catala is typographically close to\n        the name of the Catalan language written in Catalan : Català. However, the very narrow scope of our programming language should not be prone to\n        set any confusion given the existing wide influence of the Catalan language and culture."
                          }))
                }), React.createElement("div", {
                  className: "clear-right"
                }), React.createElement(PageComponents$CatalaWebsite.Section.make, {
                  title: React.createElement(Lang$CatalaWebsite.$$String.make, {
                        french: "Membres du projet",
                        english: "People"
                      }),
                  children: React.createElement("ul", {
                        className: "list-disc list-inside"
                      }, React.createElement(About$Person, {
                            person: People$CatalaWebsite.marieAlauzen
                          }), React.createElement(About$Person, {
                            person: People$CatalaWebsite.alainDelaet
                          }), React.createElement(About$Person, {
                            person: People$CatalaWebsite.carolineFlori
                          }), React.createElement(About$Person, {
                            person: People$CatalaWebsite.aymericFromherz
                          }), React.createElement(About$Person, {
                            person: People$CatalaWebsite.louisGesbert
                          }), React.createElement(About$Person, {
                            person: People$CatalaWebsite.lianeHuttner
                          }), React.createElement(About$Person, {
                            person: People$CatalaWebsite.sarahLawsky
                          }), React.createElement(About$Person, {
                            person: People$CatalaWebsite.denisMerigoux
                          }), React.createElement(About$Person, {
                            person: People$CatalaWebsite.raphaelMonat
                          }), React.createElement(About$Person, {
                            person: People$CatalaWebsite.jonathanProtzenko
                          }))
                }), React.createElement(PageComponents$CatalaWebsite.Section.make, {
                  title: React.createElement(Lang$CatalaWebsite.$$String.make, {
                        french: "Alumni",
                        english: "Alumni"
                      }),
                  children: React.createElement("ul", {
                        className: "list-disc list-inside"
                      }, React.createElement(About$Person, {
                            person: People$CatalaWebsite.justineBanuls
                          }), React.createElement(About$Person, {
                            person: People$CatalaWebsite.nicolasChataing
                          }), React.createElement(About$Person, {
                            person: People$CatalaWebsite.emileRolley
                          }), React.createElement(About$Person, {
                            person: People$CatalaWebsite.lilyaSlimani
                          }))
                }));
}

var make = About;

export {
  Person ,
  make ,
}
/* react Not a pure module */
