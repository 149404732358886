// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Client from "react-dom/client";
import * as Nav$CatalaWebsite from "./components/Nav.bs.js";
import * as Lang$CatalaWebsite from "./utils/Lang.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Footer$CatalaWebsite from "./components/Footer.bs.js";
import * as Router$CatalaWebsite from "./Router.bs.js";
import * as NavComponent$CatalaWebsite from "./components/NavComponent.bs.js";

((require("./css/tailwind.css")));

((require("../assets/css/global.css")));

((require("../assets/css/form.css")));

((require("../assets/css/catala_code.css")));

function Index$App(props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = Nav$CatalaWebsite.urlToNavElem(url);
  var langUrl = match[0];
  var langUrl$1 = langUrl !== undefined ? langUrl : /* English */1;
  var match$1 = React.useState(function () {
        return langUrl$1;
      });
  var setLang = match$1[1];
  var setLang$1 = function (param) {
    Curry._1(setLang, Lang$CatalaWebsite.newLangFromOldLang);
  };
  return React.createElement(Lang$CatalaWebsite.Context.make, {
              value: [
                match$1[0],
                setLang$1
              ],
              children: React.createElement("div", {
                    className: "flex flex-col min-h-screen text-xl md:text-lg lg:text-base"
                  }, React.createElement(NavComponent$CatalaWebsite.make, {}), React.createElement("main", {
                        className: "flex flex-col flex-grow container mx-auto px-4 lg:px-0 "
                      }, React.createElement(Router$CatalaWebsite.make, {})), React.createElement("div", {
                        className: "w-full bottom-0"
                      }, React.createElement(Footer$CatalaWebsite.make, {})))
            });
}

var App = {
  make: Index$App
};

Client.createRoot(Belt_Option.getExn(Caml_option.nullable_to_opt(document.querySelector("#app")))).render(React.createElement(React.StrictMode, {
          children: React.createElement(Index$App, {})
        }));

export {
  App ,
}
/*  Not a pure module */
