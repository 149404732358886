// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Nav$CatalaWebsite from "./Nav.bs.js";
import * as Icon$CatalaWebsite from "./Icon.bs.js";
import * as Lang$CatalaWebsite from "../utils/Lang.bs.js";
import * as Link$CatalaWebsite from "./Link.bs.js";

function Card$Basic(props) {
  return React.createElement("div", {
              className: "text-background my-4 p-1 border bg-gray_light border-gray rounded shadow-sm"
            }, React.createElement("div", {
                  className: "overflow-x-auto"
                }, props.children));
}

var Basic = {
  make: Card$Basic
};

function renderPresentationCard(lang, card, id) {
  var buttonStyle = "cursor-pointer bg-button_bg py-2 px-4 text-button_fg text-base inline-flex items-center rounded font-semibold font-sans hover:bg-button_bg_hover hover:text-button_fg_hover ease-in duration-100";
  var match = card.action;
  var action;
  if (match !== undefined) {
    var navs = match[0];
    if (navs.TAG === /* Internal */0) {
      var navs$1 = navs._0;
      action = React.createElement("button", {
            className: buttonStyle,
            onClick: (function (param) {
                Nav$CatalaWebsite.goTo(navs$1, lang);
              })
          }, React.createElement(Icon$CatalaWebsite.make, {
                className: "pr-2",
                name: "double_arrow"
              }), match[1]);
    } else {
      action = React.createElement(Link$CatalaWebsite.Button.make, {
            className: buttonStyle,
            target: navs._0,
            children: null
          }, React.createElement(Icon$CatalaWebsite.make, {
                className: "pr-2",
                name: "double_arrow"
              }), match[1]);
    }
  } else {
    action = React.createElement("div", undefined);
  }
  var quote = card.quote;
  var quote$1 = quote !== undefined ? React.createElement("blockquote", {
          className: "text-green pb-4"
        }, React.createElement("strong", undefined, Caml_option.valFromOption(quote))) : React.createElement("div", undefined);
  var icon = card.icon;
  var icon$1 = icon !== undefined ? React.createElement(Icon$CatalaWebsite.make, {
          className: "pr-4",
          name: icon
        }) : React.createElement("span", undefined);
  return React.createElement("div", {
              key: id,
              className: "w-full lg:w-1/2"
            }, React.createElement("div", {
                  className: "p-4 h-full"
                }, React.createElement("div", {
                      className: "bg-white h-full overflow-hidden shadow-sm border-solid border-gray border rounded"
                    }, React.createElement("div", {
                          className: "flex flex-col justify-between h-full px-6 py-4 text-background "
                        }, React.createElement("div", undefined, React.createElement("div", {
                                  className: " flex flex-row flex-nowrap items-center text-xl sm:text-2xl pb-2 font-bold"
                                }, icon$1, card.title), quote$1, React.createElement("p", {
                                  className: "text-base sm:text-lg pb-4"
                                }, card.content)), React.createElement("div", {
                              className: "inline-flex justify-end"
                            }, action)))));
}

function Card$Presentation$FromList(props) {
  var match = React.useContext(Lang$CatalaWebsite.langContext);
  var lang = match[0];
  return React.createElement("div", {
              className: "flex flex-row flex-wrap items-stretch"
            }, Belt_Array.mapWithIndex(props.cards, (function (i, card) {
                    return renderPresentationCard(lang, card, String(i));
                  })));
}

var FromList = {
  make: Card$Presentation$FromList
};

var Presentation = {
  renderPresentationCard: renderPresentationCard,
  FromList: FromList
};

export {
  Basic ,
  Presentation ,
}
/* react Not a pure module */
