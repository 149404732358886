// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Nav$CatalaWebsite from "./Nav.bs.js";
import * as Lang$CatalaWebsite from "../utils/Lang.bs.js";

function Button$Internal(props) {
  var target = props.target;
  var match = React.useContext(Lang$CatalaWebsite.langContext);
  var lang = match[0];
  return React.createElement("button", {
              className: "cursor-pointer bg-button_bg py-2 px-4 text-button_fg text-base inline-flex items-center rounded font-semibold font-sans shadow-sm hover:bg-button_bg_hover hover:text-button_fg_hover ease-in duration-150 ",
              onClick: (function (param) {
                  Nav$CatalaWebsite.goTo(target, lang);
                })
            }, props.children);
}

var Internal = {
  make: Button$Internal
};

function Button$Small(props) {
  var style = props.style;
  var style$1 = style !== undefined ? style : "";
  return React.createElement("button", {
              className: "cursor-pointer bg-button_bg px-1 text-button_fg text-base inline-flex items-center rounded font-semibold font-sans shadow-sm hover:bg-button_bg_hover hover:text-button_fg_hover focus:bg-button_fg focus:text-button_bg ease-in duration-150 " + style$1,
              onClick: props.onClick
            }, props.children);
}

var Small = {
  make: Button$Small
};

function Button(props) {
  return React.createElement("button", {
              className: "cursor-pointer bg-button_bg py-2 px-4 text-button_fg text-base inline-flex items-center rounded font-semibold font-sans shadow-sm hover:bg-button_bg_hover hover:text-button_fg_hover ease-in duration-150 ",
              onClick: props.onClick
            }, props.children);
}

var make = Button;

export {
  Internal ,
  Small ,
  make ,
}
/* react Not a pure module */
